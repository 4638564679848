import * as signalR from "@microsoft/signalr";
import actions from "../payfile/action";
import noti_actions from "../userNotification/action";
import _ from "lodash";
import { toast } from "react-toastify";
import AuthService from "../../modules/Auth/AuthService";

const createSignalRMiddleware = () => {
  let socket = null;
  let connectionId = null;
  //console.log("in signalr middle ware");
  const onNewPayFileOneAdded = (store) => (event) => {
    // const payload = JSON.parse(store.data);
    //console.log("onNewPayFileOneAdded called");
    const formData = {
      PageNo: 1,
      PageSize: 10,
      SortBy: "file_id",
      SortOrder: "desc",
    };
    store.dispatch(actions.getSummaryList(formData));
  };
  const UpdateProgressBar = (store) => (
    _fileId,
    _totalCompleted,
    _totalErrors,
    _totalIgnored,
    _totalUpdated
  ) => {
    // const payload = JSON.parse(store.data);

    // console.log("updateProgressBar invoked");
    // console.log(_fileId);
    // console.log(_totalCompleted);
    // console.log(_totalErrors);
    // console.log(_totalIgnored);
    // console.log(_totalUpdated);
    var currentData = {
      file_id: _fileId,
      total_completed: _totalCompleted,
      total_errors: _totalErrors,
      total_updates: _totalUpdated,
      total_ignored: _totalIgnored,
    };
    store.dispatch(actions.updateFileProgress(currentData));
  };
  const ChangeFileStatus = (store) => (fileId, fileStatus) => {
    //console.log("ChangeFileStatus invoked!");
    store.dispatch(actions.changeFileStatus(fileId, fileStatus));
  };
  const OnUploadException = (store) => (message) => {
    setTimeout(
      () => toast.error("Something went wrong while Uploading file!"),
      9999
    );
    console.log("File Upload Exception: ", message);
  };
  const sendNotificationToUser = (store) => (notification) => {
    console.log("sendNotificationToUser is invoked");
    store.dispatch(noti_actions.UpdateNotifications(notification));
  };
  // the middleware part of this function
  return (store) => (next) => async (action) => {
    //debugger;
    // const isApiAuthorized = await AuthService._checkStatus().then((data) => {
    //   return data;
    // });
    const userProfile = AuthService.getProfile();
    const isAuthenticated = AuthService.isAuthenticated();
    //console.log("isAuthenticated", isAuthenticated);
    if (
      socket == null &&
      userProfile &&
      userProfile.memberData != null &&
      isAuthenticated
    ) {
      //console.log("socket is null");
      socket = createMyWebsocket(
        `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
      );
      socket.on("connected", (ConnectionId) => {
        store.dispatch(actions.wsConnected(socket));
      });
      socket.on("disconnected", (exception) => {
        //console.log(`disconnected due to exception(${exception})`);
        store.dispatch(actions.wsDisconnected(exception));
      });
      socket.on("NewPayFileOneAdded", onNewPayFileOneAdded(store));

      socket.on("updateProgressBar", UpdateProgressBar(store));

      socket.onreconnected((connectionId) => {
        //console.log(`Connection reconnected  successfully`);
        socket.invoke("GetConnectionId").then(function(connectionId) {
          store.dispatch(actions.setConnectionId(connectionId));
        });
      });

      socket.onreconnecting((Error) => {
        console.log(`Started reconnecting, due to ${Error}`);
      });
      socket.on("updateFileStatus", ChangeFileStatus(store));
      socket.on("sendNotificationToUser", sendNotificationToUser(store));
      socket.onclose(async (Error) => {
        // console.log(
        //   `Connection closed due to error ${Error}. Try refreshing this page to restart the connection.`
        // );

        if (_.isEmpty(socket)) {
          socket = createMyWebsocket(
            `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
          );
        } else if (
          !socket.connectionStarted &&
          socket.connectionState == "Disconnected"
        ) {
          // console.log("here");
          //debugger;
          store.dispatch(actions.wsDisconnected(Error));
          await StartSignalRWithInstance(socket, store);
        } else {
          //console.log("we are still live!");
        }
      });

      ///events registration end here///
      await StartSignalRWithInstance(socket, store);

      ///// Socket is not empty
    } else {
      //console.log("socket is not null or user is not authenticated=> ", socket);

      if (
        userProfile &&
        userProfile.memberData != null &&
        isAuthenticated &&
        !socket.connectionStarted &&
        socket.connectionState == "Disconnected"
      ) {
        // console.log(
        //   "socket is not null but it is in disconnected mode",
        //   socket
        // );
        await StartSignalRWithInstance(socket, store);
        store.dispatch(actions.wsConnected(socket));
      }
      //console.log("socket initialization end", socket);
    }
    switch (action.type) {
      case "WS_CONNECT":
        if (socket !== null) {
          socket.stop();
        }

        // // websocket handlers
        socket = createMyWebsocket(action.payload);
        socket.on("connected", (ConnectionId) => {
          store.dispatch(actions.wsConnected(socket));
        });
        socket.on("disconnected", (exception) => {
          //console.log(`disconnected due to exception(${exception})`);
          store.dispatch(actions.wsDisconnected(exception));
        });
        socket.on("NewPayFileOneAdded", onNewPayFileOneAdded(store));

        socket.on("updateProgressBar", UpdateProgressBar(store));

        socket.onreconnected((connectionId) => {
          //console.log(`Connection reconnected  successfully`);
          if (
            !_.isEmpty(socket) &&
            socket.connectionStarted &&
            socket.connectionState === "Connected"
          ) {
            socket
              .invoke("GetConnectionId")
              .then(function(connectionId) {
                store.dispatch(actions.setConnectionId(connectionId));
              })
              .catch((err) => {
                return ""; //console.error("Catched:  --  " + err.toString());
              });
          }
        });

        socket.onreconnecting((Error) => {
          console.log(`Started reconnecting, due to ${Error}`);
        });
        socket.on("updateFileStatus", ChangeFileStatus(store));
        socket.on("sendNotificationToUser", sendNotificationToUser(store));
        socket.onclose(async (Error) => {
          if (_.isEmpty(socket)) {
            ///due to manually stop
            //console.log(`Connection closed.`);
          } else {
            // console.log(
            //   `Connection closed due to error ${Error}. Try refreshing this page to restart the connection.`
            // );
          }

          if (_.isEmpty(socket)) {
            socket = createMyWebsocket(
              `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
            );
          } else if (
            !socket.connectionStarted &&
            socket.connectionState == "Disconnected"
          ) {
            // console.log("here");
            //debugger;
            store.dispatch(actions.wsDisconnected(Error));
            await StartSignalRWithInstance(socket);
          } else {
            //console.log("we are still live!");
          }
        });
        socket.on("OnUploadException", OnUploadException(store));
        store.dispatch(actions.wsConnecting(socket));
        // // connect to the remote host
        await StartSignalRWithInstance(socket, store);
        store.dispatch(actions.wsConnected(socket));

        break;

      case "WS_DISCONNECT":
        if (socket !== null) {
          socket.stop();
        }
        socket = null;
        store.dispatch(actions.wsDisconnected(socket));
        //console.log("websocket closed");
        break;

      default:
        // console.log("the next action:", action);
        return next(action);
    }
  };
};
// async function StartSignalR() {
//   try {
//     var socket = createMyWebsocket(
//       `${process.env.REACT_APP_API_BASE_URL}/updateProgress`
//     );
//     await socket.start();
//     console.log("connected ", socket);
//   } catch (err) {
//     console.log(
//       "Error Connecting",
//       `Connection closed due to error ${err}. Try refreshing this page to restart the connection.`
//     );
//     console.log("starting again hub connection, from catch");
//     setTimeout(() => StartSignalR(), 5000);
//   }
// }

async function StartSignalRWithInstance(socket, store) {
  try {
    //debugger;
    if (
      !_.isEmpty(socket) &&
      !socket.connectionStarted &&
      socket.connectionState === "Disconnected"
    ) {
      await socket
        .start()
        .then(() => {
          if (
            !_.isEmpty(socket) &&
            socket.connectionStarted &&
            socket.connectionState === "Connected"
          ) {
            socket
              .invoke("GetConnectionId")
              .then(function(connectionId) {
                store.dispatch(actions.setConnectionId(connectionId));
              })
              .catch((err) => {
                return ""; //console.error("Catched:  --  " + err.toString());
              });
          }
        })
        .catch((err) => {
          return ""; //console.error("Catched:  --  " + err.toString());
        });
      //console.log("connected, from connection restarter");
    }
  } catch (err) {
    // console.log(
    //   "Error Connecting",
    //   `Connection closed due to error ${err}. Try refreshing this page to restart the connection.`
    // );
    //console.log("starting again hub connection");
    setTimeout(() => StartSignalRWithInstance(socket, store), 5000);
  }
}
const getloggingConfiguration = () => {
  if (process.env.NODE_ENV === "development") {
    return signalR.LogLevel.Critical;
  } else {
    return signalR.LogLevel.Critical;
  }
};

const createMyWebsocket = (url) => {
  //console.log("In create socket");
  let connection = new signalR.HubConnectionBuilder()
    .withAutomaticReconnect()
    .withUrl(url, { accessTokenFactory: () => localStorage.getItem("token") })
    .configureLogging(getloggingConfiguration())
    .build();

  // debugger;
  return connection;
};
export default createSignalRMiddleware();
