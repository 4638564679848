import React, { Component, Fragment } from "react";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import AUX from "../../../../hoc/Aux_";
import _ from "lodash";
import PrimaryStar from "../../../Shared/PrimaryStar";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import * as Constants from "./PrimaryEnum";
import { toast } from "react-toastify";
import axios from "../../../Shared/auth-header";

import AddressPopup from "../../../Shared/AddressPopup";
import EmailPopup from "../../../Shared/EmailPopup";
import PhonePopup from "../../../Shared/PhonePopup";

import AddressModal from "../../../Shared/AddressModal";
import EmailModal from "../../../Shared/EmailModal";
import PhonesModal from "../../../Shared/PhonesModal";
import Services from "./EditUserService";
import VerificationModal from "../../../Shared/VerificationModal";
import ConfirmationModal from "../../../Shared/ConfirmationModal";
import FacebookIDModal from "./FacebookIDModal";
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAddress: [],
      profile: {
        isenabled: false,
        islocked: false,
        isverified: false,
        username: "",
        password: "",
        addresses: [],
        phones: [],
        emailAddresses: [],
        phoneTypes: [],
        addressTypes: [],
      },
      showFacebookIdModal: false,
      FacebookId: "",
      FacebookEdit: false,
      addressType: "",
      showModal: false,
      showPhoneModal: false,
      showEmailModal: false,
      editItem: false,
      newTitle: "",
      editItemId: "",
      showVerficationModal: false,
      memberPhone: "",
      confirmDelete: false,
      phoneNumber: "",
      delType: "",
      delId: "",
      PACShow: false,
      DRFShow: false,
      NCFShow: false,
    };
    //this.GetVirtualCard = this.GetVirtualCard.bind(this);
    this.toggleMask = this.toggleMask.bind(this);
    this.changeHandlerState = this.changeHandlerState.bind(this);
    this.openFacebookIdModal = this.openFacebookIdModal.bind(this);
    this.closeFacebookIdModal = this.closeFacebookIdModal.bind(this);
  }
  toggleMask(mask, isShow) {
    switch (mask) {
      case "PAC":
        this.setState({ PACShow: isShow });
        break;
      case "DRF":
        this.setState({ DRFShow: isShow });
        break;
      case "NCF":
        this.setState({ NCFShow: isShow });
        break;
      default:
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ profile: nextProps.profile });
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  EditItem = (id, oldTitle) => {
    this.setState({
      editItemId: id,
      newTitle: oldTitle,
    });
  };

  SelectAddress = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    var temp = [];
    temp = this.state.profile;
    var current = temp.addresses.filter((x) => x.id === id);
    this.state.profile.addresses = current;
    this.setState({ profile: this.state.profile });
  };

  SelectEmail = (id) => {
    // var temp = [];
    // temp = this.state.profile;
    // var current = temp.emailAddresses.filter((x) => x.id === id);
    // this.state.profile.emailAddresses = current;
    // this.setState({ profile: this.state.profile });
  };

  SelectPhone = (id) => {
    var temp = [];
    temp = this.state.profile;
    var current = temp.phones.filter((x) => x.id === id);
    this.state.profile.phones = current;
    this.setState({ profile: this.state.profile });
  };

  DeleteProcess = (e, type, id, primary, verified) => {
    e.preventDefault();
    e.stopPropagation();
    if (verified === false || _.isEmpty(verified)) {
      this.showSpinner();
      let Url =
        "/api/Member/DeleteMemberContactInfo?primaryType=" + type + "&id=" + id;
      axios.post(Url).then(({ data }) => {
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            this.props.LoadData(this.props.memberid);
          } else {
            this.hideSpinner();
            toast.error(data.message);
          }
        } else {
          toast.error("Information can not be deleted.");
          this.hideSpinner();
        }
      });
    } else {
      var temp = [];
      temp = this.state.profile;
      var current = temp.phones.filter((x) => x.id === id);
      this.setState({
        phoneNumber: current[0].number,
        delType: type,
        delId: id,
      });
      this.confirm(type, id);
      this.toggleConfirm();
    }
  };
  //async GetVirtualCard() {
  //  $(".loading").show();
  //  let data = await Services.GetVirtualCard(this.props.memberid);
  //  $(".loading").hide();
  //}
  toggleConfirm = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };

  confirm = (type, id) => {
    this.confirmed = () => {
      if (this.state.index !== -1) {
        let Url =
          "/api/Member/DeleteMemberContactInfo?primaryType=" +
          type +
          "&id=" +
          id;
        axios.post(Url).then(({ data }) => {
          this.hideSpinner();
          if (data.status) {
            if (data.status.toLowerCase() === "success") {
              toast.success(data.message);
              this.props.LoadData(this.props.memberid);
              this.setState({ delType: "", delId: "" });
            } else {
              this.hideSpinner();
              this.setState({ delType: "", delId: "" });
              toast.error(data.message);
            }
          } else {
            this.setState({ delType: "", delId: "" });
            toast.error("Information can not be deleted.");
          }
        });
      }
      this.toggleConfirm();
    };
    return this;
  };

  changeHandlerAddress = (e) => {
    const idx = e.target.parentElement.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    let name = e.target.name;
    if (name != "address2") {
      if (e.target.value == null || e.target.value == "") {
        this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
          "Please Provide " + e.target.dataset.name;
      }
    }
    this.props.setValidateUSPS(true);
    this.setState({ profile: this.state.profile });
  };
  changeHandlerState = async (e, value, idx) => {
    let valueV = "";
    if (value && value.inputValue) {
      this.state.profile["addresses"][idx]["state"] = value.inputValue;
      valueV = value.inputValue;
    } else {
      this.state.profile["addresses"][idx]["state"] = value;
      valueV = value;
    }

    if (valueV == null || valueV == "" || valueV == undefined) {
      await this.props.setStateError(true);
    } else {
      await this.props.setStateError(false);
    }
    await this.props.setValidateUSPS(true);
    this.setState({ profile: this.state.profile });
  };
  openModal = () => {
    this.setState({ showModal: true });
  };

  openPhoneModal = () => {
    this.setState({ showPhoneModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closePhoneModal = () => {
    this.setState({ showPhoneModal: false });
  };

  openVerificationModal = () => {
    if (this.props.member.allowtextmessages === false) {
      this.setState({ showVerficationModal: true });
    } else {
      this.setState({ showVerficationModal: false });
    }
  };

  ///////////////Close event for Verification Modal
  closeVerificationModal = () => {
    this.setState({ showVerficationModal: false });
  };
  openFacebookIdModal = () => {
    if (this.props.member.allowfacebook === false) {
      this.setState({ showFacebookIdModal: true });
    } else {
      this.setState({ showFacebookIdModal: false });
    }
  };
  closeFacebookIdModal = () => {
    this.setState({ showFacebookIdModal: false });
  };
  OnEdit = (id) => {
    // this.setState({ editItem: true, editItemId: id });
    // $(".editEmailForm").removeClass("d-none");

    var result = this.props.profile.emailAddresses.find(
      (item) => item.id == id
    );
    this.setState({ currentEmail: result });
    this.setState({ showEmailModal: true });
  };

  OnCancel = () => {
    this.setState({ editItem: false });
    $(".editEmailForm").addClass("d-none");
    this.props.LoadData(this.props.memberid);
  };
  openEmailModal = () => {
    this.setState({ currentEmail: {} });
    this.setState({ showEmailModal: true });
  };
  closeEmailModal = () => {
    this.setState({ showEmailModal: false });
  };

  handleChange = (e, id) => {
    var name = this.state.profile.emailAddresses[0].email;

    this.state.profile.emailAddresses[0].email = e.target.value;

    //console.log(this.state.profile);
    this.setState({
      profile: this.state.profile,
    });
  };
  handleAddressesClick = (e) => {
    // e.preventDefault();
    //console.log(e.currentTarget);
  };
  getGenderLabel = (gender) => {
    if (!_.isEmpty(gender)) {
      if (gender == "M" || gender == "Male") {
        return "Male";
      }
      if (gender == "F" || gender == "Female") {
        return "Female";
      }
      if (gender == "X") {
        return "Nonbinary and/or Intersex";
      }
      if (gender == "N") {
        return "Prefer not to answer";
      }
    } else {
      return "None";
    }
  };
  render() {
    let styles = {
      position: "absolute",
      right: "26px",
      top: "4px",
    };

    const {
      detail,
      member,
      headerLabel,
      profileform,
      handleChangeAllowance,
      calculate_age,
      formate_amount,
      handleResetProfile,
      changeHandlerPf_text,
      changeHandlerShirtSizes,
      shirtsizes,
      showAllowedContacts,
      changeHandlerGender,
      changeHandlerPf_date,
      changeHandlerPf_amount,
      handleFormSubmitProfile,
      memberid,
      profile,
    } = this.props;
    let memberTypeDesc = null;
    if (detail.membertype) {
      memberTypeDesc = detail.membertype.description;
    }
    let firstSectionPerm =
      AuthService.canMemberView(PermissionFeature.Region) ||
      AuthService.canMemberView(PermissionFeature.Facility);

    let secondSectionAllowedPermissions =
      AuthService.canOwnView(
        PermissionFeature.CommunicationAllowEmails,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.CommunicationAllowEmails) ||
      AuthService.canOwnView(
        PermissionFeature.CommunicationAllowPostalMail,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.CommunicationAllowPostalMail
      ) ||
      AuthService.canOwnView(
        PermissionFeature.CommunicationAllowTextMessages,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.CommunicationAllowTextMessages
      ) ||
      AuthService.canOwnView(
        PermissionFeature.CommunicationAllowPhoneCall,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.CommunicationAllowPhoneCall
      ) ||
      AuthService.canOwnView(
        PermissionFeature.CommunicationAllowFacebook,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.CommunicationAllowFacebook);
    let secondSectionPerm =
      AuthService.canOwnView(
        PermissionFeature.ContactAddresses,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.ContactAddresses) ||
      AuthService.canOwnView(
        PermissionFeature.ContactEmailAddresses,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.ContactEmailAddresses) ||
      AuthService.canOwnView(
        PermissionFeature.ContactPhones,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.ContactPhones) ||
      AuthService.canOwnView(
        PermissionFeature.MembersCommunication,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.MembersCommunication) ||
      secondSectionAllowedPermissions;

    let thirdSectionPerm =
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoNickname,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoNickname) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoSpouseName,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoSpouseName) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoPreviousLastName,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.PersonalInfoPreviousLastName
      ) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoShirtSize,
        this.props.memberid
      ) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoVetStatus,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoVetStatus) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoShirtSize) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoGender,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoGender) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoDateOfBirth,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoDateOfBirth) ||
      AuthService.canOwnView(
        PermissionFeature.DatesMandatoryRetirementDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.DatesMandatoryRetirementDate
      ) ||
      AuthService.canOwnView(
        PermissionFeature.DatesPlannedRetirementDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesPlannedRetirementDate) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialPacDonation,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialPacDonation) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialNcfDonation,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialNcfDonation) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialDrfDonation,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialDrfDonation);

    return (
      <AUX>
        <div className="card directory-card border-rounded shadow">
          <h5 className="card-header position-relative mt-0">
            <i className="fas fa-user mr-2" />
            {(AuthService.canOwnView(
              PermissionFeature.PersonalInfoFirstName,
              this.props.memberid
            ) ||
              AuthService.canMemberView(
                PermissionFeature.PersonalInfoFirstName
              )) &&
              member.firstname}{" "}
            {(AuthService.canOwnView(
              PermissionFeature.PersonalInfoNickname,
              this.props.memberid
            ) ||
              AuthService.canMemberView(
                PermissionFeature.PersonalInfoNickname
              )) &&
              member.nickname &&
              `"${member.nickname}"`}{" "}
            {(AuthService.canOwnView(
              PermissionFeature.PersonalInfoMiddleName,
              this.props.memberid
            ) ||
              AuthService.canMemberView(
                PermissionFeature.PersonalInfoMiddleName
              )) &&
              member.middlename}{" "}
            {(AuthService.canOwnView(
              PermissionFeature.PersonalInfoLastName,
              this.props.memberid
            ) ||
              AuthService.canMemberView(
                PermissionFeature.PersonalInfoLastName
              )) &&
              member.lastname}
            <span class="float-right">
              <i>
                {(AuthService.canOwnView(
                  PermissionFeature.PersonalInfoMemberNumber,
                  this.props.memberid
                ) ||
                  AuthService.canMemberView(
                    PermissionFeature.PersonalInfoMemberNumber
                  )) &&
                  member.membernumber}
              </i>
              <br />
              {(this.props.member.membertypeid == 6 ||
                this.props.member.membertypeid == 8 ||
                this.props.member.membertypeid == 10 ||
                !this.props.isMyProfile) && (
                <a
                  style={{ fontSize: 14, right: "21px", bottom: "1px" }}
                  onClick={() => {
                    $(".loading").show();
                    setTimeout(function() {
                      $(".loading").hide();
                    }, 700);
                  }}
                  href={`${axios.defaults.baseURL}/api/Member/MemberCard/${this.props.memberid}`}
                  className="font-italic position-absolute c-pointer text-info"
                >
                  <small>Virtual Member Card</small>
                </a>
              )}
            </span>
          </h5>
          <div className="card-body pb-3 pt-1 pl-2 pr-2">
            <div className="memberProfile">
              {/*Current Member Widget*/}
              {firstSectionPerm && (
                <Fragment>
                  <div
                    className={
                      (secondSectionPerm || thirdSectionPerm) && "border-bottom"
                    }
                  >
                    <h6>
                      <span className="text-primary">{memberTypeDesc}</span>
                      <span className="float-right font-weight-normal">
                        {// checking facility code and permission
                        AuthService.canMemberView(PermissionFeature.Facility) &&
                          detail.facility && (
                            // Print Facility Code
                            <a
                              alt="show Teams"
                              href={
                                "/directory/facility/view/" + detail.facility.id
                              }
                            >
                              {detail.facility.code}{" "}
                            </a>
                          )}
                        {AuthService.canMemberView(PermissionFeature.Region) &&
                          AuthService.canMemberView(
                            PermissionFeature.Facility
                          ) &&
                          detail.facility.code != null &&
                          detail.facility.code != "" &&
                          detail.region.code != null &&
                          detail.region.code != "" &&
                          " - "}
                        {AuthService.canMemberView(PermissionFeature.Region) &&
                          detail.region && (
                            <a
                              alt="show Teams"
                              href={
                                "/directory/region/view/" + detail.region.id
                              }
                            >
                              {detail.region.code}{" "}
                            </a>
                          )}
                      </span>
                    </h6>
                  </div>

                  <div className="clearfix" />
                </Fragment>
              )}
              {/*Current Member Widget END HERE*/}

              {secondSectionPerm && (
                <Fragment>
                  <div class="container">
                    <div className="row pb-3 mt-3 px-lg-1 px-2">
                      <div className="col-md-6 col-xs-12 mx-0 pl-0 pr-0">
                        {/* Addresses start here */}
                        <div className="col-sm-12 col-xs-12 col-lg-12 col-xl-12 col-md-12 pl-0 pr-0">
                          {(AuthService.canOwnView(
                            PermissionFeature.ContactAddresses,
                            this.props.memberid
                          ) ||
                            AuthService.canMemberView(
                              PermissionFeature.ContactAddresses
                            )) && (
                            <Fragment>
                              <div className="pb-3 ml-3">
                                <h6 className="card-subtitle mb-2 text-muted">
                                  Addresses{" "}
                                  {(AuthService.canOwnAdd(
                                    PermissionFeature.ContactAddresses,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberAdd(
                                      PermissionFeature.ContactAddresses
                                    )) && (
                                    <i
                                      class="fa fa-plus-circle"
                                      onClick={this.openModal}
                                      // data-toggle="modal"
                                      // data-backdrop="static"
                                      // data-target="#EditAddressModal"
                                    />
                                  )}
                                </h6>
                                <p className="card-text">
                                  {member.addresses.map((item, idx) => (
                                    <p
                                      className="position-relative mb-0"
                                      key={"index-" + idx}
                                    >
                                      {/*item.isprimary && <PrimaryStar />*/}

                                      <p className="mb-0">
                                        <a
                                          target="_blank"
                                          href={
                                            "http://maps.google.com/?q=" +
                                            item.address1 +
                                            ", " +
                                            item.address2 +
                                            item.address1 +
                                            ", " +
                                            item.city +
                                            ", " +
                                            item.state +
                                            " " +
                                            item.zipcode +
                                            " "
                                          }
                                          onClick={(e) =>
                                            this.handleAddressesClick(e, item)
                                          }
                                        >
                                          {/* <span>{item.address1} </span>
                                            <br />
                                            <span>{item.address2} </span>
                                            <br />
                                            <span>
                                              {item.city} {item.state}{" "}
                                              {item.zipcode}{" "}
                                            </span> */}

                                          <p className="pt-0 pb-0 mt-0 mb-0">
                                            {item.address1}{" "}
                                          </p>
                                          <p className="pt-0 pb-0 mt-0 mb-0">
                                            {item.address2}{" "}
                                          </p>
                                          <p className="pt-0 pb-0 mt-0 mb-0">
                                            {item.city} {item.state}{" "}
                                            {item.zipcode}{" "}
                                            <span className="oneLine">
                                              {item.isValidAddress && (
                                                <i
                                                  title={"Verified"}
                                                  class="fas fa-check-circle"
                                                  style={{
                                                    color: "green",
                                                    marginRight: "0.2rem",
                                                  }}
                                                />
                                              )}
                                              {
                                                <PrimaryStar
                                                  starTarget="address"
                                                  checkType={Constants.ADDRESS}
                                                  memberid={memberid}
                                                  id={item.id}
                                                  isprimary={item.isprimary}
                                                  refreshData={
                                                    this.props.LoadData
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              }{" "}
                                              {(AuthService.canOwnUpdate(
                                                PermissionFeature.ContactAddresses,
                                                this.props.memberid
                                              ) ||
                                                AuthService.canMemberUpdate(
                                                  PermissionFeature.ContactAddresses
                                                )) && (
                                                <i
                                                  class="fas fa-sm fa-pencil-alt edit-pencil-icon"
                                                  data-toggle="modal"
                                                  data-backdrop="static"
                                                  data-target="#EditAddressModal"
                                                  onClick={(e) =>
                                                    this.SelectAddress(
                                                      e,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              )}{" "}
                                              {(AuthService.canOwnDelete(
                                                PermissionFeature.ContactAddresses,
                                                this.props.memberid
                                              ) ||
                                                AuthService.canMemberDelete(
                                                  PermissionFeature.ContactAddresses
                                                )) && (
                                                <i
                                                  className="fas fa-trash-alt"
                                                  title={"Delete"}
                                                  style={{
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) => {
                                                    this.DeleteProcess(
                                                      e,
                                                      Constants.ADDRESS,
                                                      item.id,
                                                      item.isprimary
                                                    );
                                                  }}
                                                />
                                              )}
                                            </span>
                                          </p>
                                        </a>
                                      </p>
                                    </p>
                                  ))}
                                </p>
                              </div>
                            </Fragment>
                          )}
                        </div>
                        {/* Addresses end here */}

                        {/* Emails start here */}
                        <div className="col-sm-12 col-xs-12 col-lg-12 col-xl-12 col-md-12 pl-0 pr-0">
                          {(AuthService.canOwnView(
                            PermissionFeature.ContactEmailAddresses,
                            this.props.memberid
                          ) ||
                            AuthService.canMemberView(
                              PermissionFeature.ContactEmailAddresses
                            )) && (
                            <div className="mb-3 ml-3">
                              <h6 className="card-subtitle mb-2 text-muted">
                                Email{" "}
                                {(AuthService.canOwnAdd(
                                  PermissionFeature.ContactEmailAddresses,
                                  this.props.memberid
                                ) ||
                                  AuthService.canMemberAdd(
                                    PermissionFeature.ContactEmailAddresses
                                  )) &&
                                  headerLabel !== "" && (
                                    <i
                                      class="fa fa-plus-circle"
                                      onClick={this.openEmailModal}
                                    />
                                  )}
                              </h6>
                              <p className="card-text">
                                {profile.emailAddresses.map((item, idx) => (
                                  <div classname="position-relative">
                                    {/*item.isprimary && <PrimaryStar />*/}

                                    {
                                      <>
                                        <a
                                          target="_blank"
                                          href={"mailto:" + item.email}
                                        >
                                          {" "}
                                          <span>{item.email} </span>
                                        </a>
                                        <span className="oneLine">
                                          {
                                            <PrimaryStar
                                              checkType={Constants.EMAIL}
                                              memberid={memberid}
                                              id={item.id}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              isprimary={item.isprimary}
                                              refreshData={this.props.LoadData}
                                            />
                                          }{" "}
                                          {(AuthService.canOwnUpdate(
                                            PermissionFeature.ContactEmailAddresses,
                                            this.props.memberid
                                          ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.ContactEmailAddresses
                                            )) && (
                                            <i
                                              class="fas fa-sm fa-pencil-alt edit-pencil-icon"
                                              // data-toggle="modal"
                                              // data-backdrop="static"
                                              // data-target="#EditEmailModal"
                                              onClick={() => {
                                                //this.SelectEmail(item.id);
                                                this.OnEdit(item.id);
                                              }}
                                            />
                                          )}{" "}
                                          {(AuthService.canOwnDelete(
                                            PermissionFeature.ContactEmailAddresses,
                                            this.props.memberid
                                          ) ||
                                            AuthService.canMemberDelete(
                                              PermissionFeature.ContactEmailAddresses
                                            )) && (
                                            <i
                                              className="fas fa-trash-alt"
                                              title={"Delete"}
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) => {
                                                this.DeleteProcess(
                                                  e,
                                                  Constants.EMAIL,
                                                  item.id,
                                                  item.isprimary
                                                );
                                              }}
                                            />
                                          )}
                                        </span>
                                      </>
                                    }
                                  </div>
                                ))}
                              </p>
                            </div>
                          )}
                        </div>
                        {/* Emails end here */}
                        {/* Phones Work  start*/}
                        {(AuthService.canOwnView(
                          PermissionFeature.ContactPhones,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.ContactPhones
                          )) && (
                          <Fragment>
                            <div className="col-sm-12 col-xs-12 col-lg-12 col-xl-12 col-md-12 pl-0">
                              <div className="mb-3 ml-3">
                                <h6 className="card-subtitle mb-2 text-muted">
                                  Phone{" "}
                                  {(AuthService.canOwnAdd(
                                    PermissionFeature.ContactPhones,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberAdd(
                                      PermissionFeature.ContactPhones
                                    )) &&
                                    headerLabel !== "" && (
                                      <i
                                        class="fa fa-plus-circle"
                                        onClick={this.openPhoneModal}
                                      />
                                    )}
                                </h6>
                                <p className="card-text">
                                  {member.phones.map((item, idx) => (
                                    <div className="position-relative">
                                      {/*item.isprimary && <PrimaryStar />*/}
                                      <a href={"tel:" + item.number}>
                                        {" "}
                                        {item.number != "" &&
                                        item.number != null ? (
                                          <span>
                                            {item.phonetype == null
                                              ? ""
                                              : item.phonetype}{" "}
                                            {item.number}{" "}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </a>
                                      {item.isVerified && (
                                        <i
                                          title={"Verified"}
                                          class="fas fa-check-circle"
                                          style={{
                                            color: "green",
                                            marginRight: "0.2rem",
                                          }}
                                        />
                                      )}
                                      <span className="oneLine">
                                        {(item.phonetypeid === 1 ||
                                          item.phonetypeid === 3) && (
                                          <PrimaryStar
                                            checkType={Constants.PHONE}
                                            memberid={memberid}
                                            id={item.id}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            isprimary={item.isprimary}
                                            refreshData={this.props.LoadData}
                                          />
                                        )}
                                        {(AuthService.canOwnUpdate(
                                          PermissionFeature.ContactPhones,
                                          this.props.memberid
                                        ) ||
                                          AuthService.canMemberUpdate(
                                            PermissionFeature.ContactPhones
                                          )) && (
                                          <i
                                            class="fas fa-sm fa-pencil-alt edit-pencil-icon"
                                            data-toggle="modal"
                                            data-backdrop="static"
                                            data-target="#EditPhonesModal"
                                            onClick={() =>
                                              this.SelectPhone(item.id)
                                            }
                                          />
                                        )}{" "}
                                        {(AuthService.canOwnDelete(
                                          PermissionFeature.ContactPhones,
                                          this.props.memberid
                                        ) ||
                                          AuthService.canMemberDelete(
                                            PermissionFeature.ContactPhones
                                          )) && (
                                          <i
                                            className="fas fa-trash-alt"
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            title={"Delete"}
                                            onClick={(e) => {
                                              this.DeleteProcess(
                                                e,
                                                Constants.PHONE,
                                                item.id,
                                                item.isprimary,
                                                item.isVerified
                                              );
                                            }}
                                          />
                                        )}
                                      </span>

                                      <br />
                                    </div>
                                  ))}
                                </p>
                              </div>
                            </div>
                          </Fragment>
                        )}

                        {/* Phones Work End*/}
                      </div>
                      <div className="col-md-6 col-xs-12 mx-0 pl-0 pr-0">
                        <div className="col-sm-12 col-xs-12 col-lg-12 col-xl-12  col-md-12 pl-0 pr-0">
                          {/*Communication Preferences for Allow Email, Allow post etc etc*/}
                          {secondSectionAllowedPermissions && (
                            <Fragment>
                              <div className="CommunicationPreferences ml-3">
                                <h6 className="card-subtitle mb-2 text-muted">
                                  Communication Preferences{" "}
                                  {true && (
                                    <>
                                      <i
                                        onClick={() => {
                                          $("#allowanceEdit").addClass(
                                            "d-none"
                                          );
                                          $(".cancelPensal").addClass("d-none");
                                          $(
                                            "#pencilEditCommunication"
                                          ).removeClass("d-none");
                                          $("#allowance").removeClass("d-none");
                                        }}
                                        className="cancelPensal d-none fas fa-times c-pointer"
                                      >
                                        {/* cancel edit pencil */}
                                      </i>

                                      <i
                                        onClick={() => {
                                          $("#allowanceEdit").removeClass(
                                            "d-none"
                                          );
                                          $(
                                            "#pencilEditCommunication"
                                          ).addClass("d-none");

                                          $(".cancelPensal").removeClass(
                                            "d-none"
                                          );
                                          $("#allowance").addClass("d-none");
                                        }}
                                        id="pencilEditCommunication"
                                        className="fas fa-pencil-alt edit-pencil-icon"
                                      >
                                        {/* edit pencil */}
                                      </i>
                                    </>
                                  )}
                                  <div id="allowance" className="mt-2 ml-2">
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowEmails,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowEmails
                                      )) && (
                                      <div className="card-text w-100 custom-checkbox">
                                        {member.allowemails && (
                                          <i className="fas fa-check tickIcon" />
                                        )}
                                        {!member.allowemails && (
                                          <i className="fas fa-times crossIcon" />
                                        )}
                                        <label className="custom-control-label-new">
                                          Email
                                        </label>
                                      </div>
                                    )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowPostalMail,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowPostalMail
                                      )) && (
                                      <div className="card-text w-100 custom-checkbox">
                                        {member.allowpostalmail && (
                                          <i className="fas fa-check tickIcon" />
                                        )}
                                        {!member.allowpostalmail && (
                                          <i className="fas fa-times crossIcon" />
                                        )}
                                        <label className="custom-control-label-new">
                                          Mail
                                        </label>
                                      </div>
                                    )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowPhoneCall,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowPhoneCall
                                      )) && (
                                      <div className="card-text w-100 custom-checkbox">
                                        {member.allowphonecall && (
                                          <i className="fas fa-check tickIcon" />
                                        )}
                                        {!member.allowphonecall && (
                                          <i className="fas fa-times crossIcon" />
                                        )}
                                        <label className="custom-control-label-new">
                                          Phone Call
                                        </label>
                                      </div>
                                    )}

                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowTextMessages,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowTextMessages
                                      )) && (
                                      <>
                                        <div className="card-text w-100 custom-checkbox">
                                          {member.allowtextmessages && (
                                            <i className="fas fa-check tickIcon" />
                                          )}
                                          {!member.allowtextmessages && (
                                            <i className="fas fa-times crossIcon" />
                                          )}
                                          <label className="custom-control-label-new">
                                            Text Message
                                          </label>
                                          <label
                                            className="custom-control-label-new"
                                            style={{
                                              fontSize: "14px",
                                              "margin-left": "12%",
                                            }}
                                          />
                                        </div>
                                        <span className="verifiedPhone font-weight-bold">
                                          {this.props.memberPhone !== "" &&
                                            this.props.allowance &&
                                            this.props.allowance
                                              .allowtextmessages &&
                                            this.props.memberPhone}
                                          {/* "communication phone" */}
                                        </span>
                                      </>
                                    )}

                                    {member.allowtextmessages && (
                                      <>
                                        {(AuthService.canOwnView(
                                          PermissionFeature.CommunicationAllowTextAlert
                                        ) ||
                                          AuthService.canMemberView(
                                            PermissionFeature.CommunicationAllowTextAlert
                                          )) && (
                                          <div
                                            className="card-text w-100 custom-checkbox"
                                            style={{
                                              fontSize: "14px",
                                              "margin-left": "12%",
                                            }}
                                          >
                                            {member.allowtext_newsalerts && (
                                              <i className="fas fa-check tickIcon" />
                                            )}
                                            {!member.allowtext_newsalerts && (
                                              <i className="fas fa-times crossIcon" />
                                            )}
                                            <label className="custom-control-label-new">
                                              News
                                            </label>
                                          </div>
                                        )}
                                        {(AuthService.canOwnView(
                                          PermissionFeature.CommunicationAllowLegislative
                                        ) ||
                                          AuthService.canMemberView(
                                            PermissionFeature.CommunicationAllowLegislative
                                          )) && (
                                          <div
                                            className="card-text w-100 custom-checkbox"
                                            style={{
                                              fontSize: "14px",
                                              "margin-left": "12%",
                                            }}
                                          >
                                            {member.allowtext_legislative && (
                                              <i className="fas fa-check tickIcon" />
                                            )}
                                            {!member.allowtext_legislative && (
                                              <i className="fas fa-times crossIcon" />
                                            )}
                                            <label className="custom-control-label-new">
                                              Legislative
                                            </label>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowFacebook,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowFacebook
                                      )) && (
                                      <div className="card-text w-100 custom-checkbox">
                                        {member.allowfacebook && (
                                          <i className="fas fa-check tickIcon" />
                                        )}
                                        {!member.allowfacebook && (
                                          <i className="fas fa-times crossIcon" />
                                        )}
                                        <label className="custom-control-label-new">
                                          Facebook{" "}
                                          {member.allowfacebook &&
                                            member.facebookid && (
                                              <a
                                                target="_blank"
                                                href={
                                                  "https://facebook.com/" +
                                                  member.facebookid
                                                }
                                              >
                                                {" "}
                                                <b className="font-weight-bold">
                                                  <i>({member.facebookid})</i>
                                                </b>
                                              </a>
                                            )}
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    id="allowanceEdit"
                                    className="mobile-margin-cmm visible-xs ml-md-1 mt-2 pl-2 d-none"
                                  >
                                    {/* <div className="col-12 px-0 pt-2">
                                      <i
                                        onClick={() => {
                                          $("#allowanceEdit").addClass(
                                            "d-none"
                                          );
                                          
                                          $("#pencilEditCommunication").removeClass("d-none");
                                          $("#allowance").removeClass("d-none");
                                        }}
                                        className="fas fa-times c-pointer"
                                      >
                                        cancel edit pencil
                                      </i>
                                    </div> */}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowEmails,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowEmails
                                      )) && (
                                      <div className="card-text ml-xl-3 w-100 custom-checkbox">
                                        <input
                                          type="checkbox"
                                          disabled={
                                            !AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowEmails,
                                              this.props.memberid
                                            ) &&
                                            !AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowEmails
                                            )
                                          }
                                          checked={member.allowemails}
                                          data-name="Allow Email"
                                          className="custom-control-input position-absolute"
                                          onChange={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowEmails,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowEmails
                                            )
                                              ? handleChangeAllowance
                                              : ""
                                          }
                                          id={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowEmails,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowEmails
                                            )
                                              ? "allowemails"
                                              : "call"
                                          }
                                          name={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowEmails,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowEmails
                                            )
                                              ? "allowemails"
                                              : "call"
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowEmails,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowEmails
                                            )
                                              ? "allowemails"
                                              : "call"
                                          }
                                        >
                                          Email
                                        </label>
                                      </div>
                                    )}

                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowPostalMail,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowPostalMail
                                      )) && (
                                      <div className="card-text ml-xl-3 w-100 custom-checkbox">
                                        <input
                                          type="checkbox"
                                          disabled={
                                            !AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail,
                                              this.props.memberid
                                            ) &&
                                            !AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail
                                            )
                                          }
                                          data-name="Allow Mail"
                                          onChange={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail
                                            )
                                              ? handleChangeAllowance
                                              : ""
                                          }
                                          checked={member.allowpostalmail}
                                          className="custom-control-input position-absolute"
                                          id={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail
                                            )
                                              ? "allowpostalmail"
                                              : "snailmail"
                                          }
                                          name={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail
                                            )
                                              ? "allowpostalmail"
                                              : "snailmail"
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPostalMail
                                            )
                                              ? "allowpostalmail"
                                              : "snailmail"
                                          }
                                        >
                                          Mail
                                        </label>
                                      </div>
                                    )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowPhoneCall,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowPhoneCall
                                      )) && (
                                      <div
                                        className="card-text ml-xl-3 w-100 custom-checkbox"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={member.allowphonecall}
                                          data-name="Allow Call"
                                          className="custom-control-input position-absolute"
                                          disabled={
                                            !AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall,
                                              this.props.memberid
                                            ) &&
                                            !AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall
                                            )
                                          }
                                          onChange={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall
                                            )
                                              ? handleChangeAllowance
                                              : ""
                                          }
                                          id={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall
                                            )
                                              ? "allowphonecall"
                                              : "phoneCAll"
                                          }
                                          name={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall
                                            )
                                              ? "allowphonecall"
                                              : "phoneCAll"
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowPhoneCall
                                            )
                                              ? "allowphonecall"
                                              : "phoneCAll"
                                          }
                                        >
                                          Phone Call
                                        </label>
                                      </div>
                                    )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowTextMessages,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowTextMessages
                                      )) && (
                                      <div className="card-text ml-xl-3 w-100 custom-checkbox">
                                        <input
                                          type="checkbox"
                                          data-name="Allow Text"
                                          value={
                                            this.props.member.allowtextmessages
                                          }
                                          checked={
                                            this.props.member
                                              .allowtextmessages == true
                                              ? true
                                              : false
                                          }
                                          onClick={this.openVerificationModal}
                                          className="custom-control-input position-absolute"
                                          disabled={
                                            !AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages,
                                              this.props.memberid
                                            ) &&
                                            !AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages
                                            )
                                          }
                                          onChange={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages
                                            )
                                              ? (e) => {
                                                  this.props.handleTextMessageOnChange(
                                                    e
                                                  );
                                                }
                                              : ""
                                          }
                                          id={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages
                                            )
                                              ? "allowtextmessages"
                                              : "textMessage"
                                          }
                                          name={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages
                                            )
                                              ? "allowtextmessages"
                                              : "textMessage"
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowTextMessages
                                            )
                                              ? "allowtextmessages"
                                              : "textMessage"
                                          }
                                        >
                                          Text Message
                                        </label>
                                        <label
                                          className="custom-control-label-new"
                                          style={{
                                            fontSize: "14px",
                                          }}
                                        >
                                          <span className="verifiedOpen ml-2 font-weight-bold">
                                            {this.props.memberPhone !== "" &&
                                              this.props.allowance &&
                                              this.props.allowance
                                                .allowtextmessages &&
                                              this.props.memberPhone}
                                            {/* "communication Phone Open Mode" */}
                                          </span>
                                        </label>
                                      </div>
                                    )}

                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowTextAlert
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowTextAlert
                                      )) &&
                                      member.allowtextmessages && (
                                        <div
                                          className="card-text ml-xl-3 w-100 custom-checkbox"
                                          style={{
                                            fontSize: "14px",
                                            "margin-left": "12%",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            data-name="Allow News"
                                            checked={
                                              member.allowtext_newsalerts
                                            }
                                            className="custom-control-input position-absolute"
                                            disabled={
                                              !PermissionFeature.CommunicationAllowTextAlert
                                            }
                                            onChange={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert
                                              )
                                                ? handleChangeAllowance
                                                : ""
                                            }
                                            id={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert
                                              )
                                                ? "allowtext_newsalerts"
                                                : "textMessage"
                                            }
                                            name={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowTextAlert
                                              )
                                                ? "allowtext_newsalerts"
                                                : "textMessage"
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            style={{
                                              fontSize: "14px",
                                              "margin-left": "12%",
                                            }}
                                            htmlFor={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowTextMessages,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowTextMessages
                                              )
                                                ? "allowtext_newsalerts"
                                                : "textMessage"
                                            }
                                          >
                                            News
                                          </label>
                                        </div>
                                      )}
                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowLegislative
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowLegislative
                                      )) &&
                                      member.allowtextmessages && (
                                        <div
                                          className="card-text ml-xl-3 w-100 custom-checkbox"
                                          style={{
                                            fontSize: "14px",
                                            "margin-left": "12%",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            data-name="Allow Legislative"
                                            checked={
                                              member.allowtext_legislative
                                            }
                                            className="custom-control-input position-absolute"
                                            disabled={
                                              !PermissionFeature.CommunicationAllowLegislative
                                            }
                                            //checkbox legislative input
                                            onChange={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowLegislative,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowLegislative
                                              )
                                                ? handleChangeAllowance
                                                : ""
                                            }
                                            id={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowLegislative,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowLegislative
                                              )
                                                ? "allowtext_legislative"
                                                : "textMessage"
                                            }
                                            name={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowLegislative,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowLegislative
                                              )
                                                ? "allowtext_legislative"
                                                : "textMessage"
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            style={{
                                              fontSize: "14px",
                                              "margin-left": "12%",
                                            }}
                                            for={
                                              AuthService.canOwnUpdate(
                                                PermissionFeature.CommunicationAllowLegislative,
                                                this.props.memberid
                                              ) ||
                                              AuthService.canMemberUpdate(
                                                PermissionFeature.CommunicationAllowLegislative
                                              )
                                                ? "allowtext_legislative"
                                                : "textMessage"
                                            }
                                          >
                                            Legislative
                                          </label>
                                        </div>
                                      )}

                                    {(AuthService.canOwnView(
                                      PermissionFeature.CommunicationAllowFacebook,
                                      this.props.memberid
                                    ) ||
                                      AuthService.canMemberView(
                                        PermissionFeature.CommunicationAllowFacebook
                                      )) && (
                                      <div
                                        className="card-text ml-xl-3 w-100 custom-checkbox"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            this.props.member.allowfacebook
                                          }
                                          data-name="Allow Facebook"
                                          className="custom-control-input position-absolute"
                                          onClick={this.openFacebookIdModal}
                                          disabled={
                                            !AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowFacebook,
                                              this.props.memberid
                                            ) &&
                                            !AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowFacebook
                                            )
                                          }
                                          onChange={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowFacebook,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowFacebook
                                            )
                                              ? (e) => {
                                                  this.props.handleFacebookOnChange(
                                                    e
                                                  );
                                                }
                                              : ""
                                          }
                                          id={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowFacebook,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowFacebook
                                            )
                                              ? "allowfacebook"
                                              : "facebook"
                                          }
                                          name={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowFacebook,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowFacebook
                                            )
                                              ? "allowfacebook"
                                              : "facebook"
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.CommunicationAllowFacebook,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.CommunicationAllowFacebook
                                            )
                                              ? "allowfacebook"
                                              : "facebook"
                                          }
                                        >
                                          Facebook
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                </h6>
                              </div>{" "}
                            </Fragment>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  {thirdSectionPerm && <hr className="mt-0" />}
                </Fragment>
              )}

              {/* Addresses END */}

              {/* -------------------Users Emails Addresses and Phones END HERE -----------------*/}
              {thirdSectionPerm && (
                <Fragment>
                  <div className="row pl-3 pr-3 pb-1" id="ProfileInline">
                    {(AuthService.canOwnUpdate(
                      PermissionFeature.Member,
                      this.props.memberid
                    ) ||
                      AuthService.canMemberUpdate(
                        PermissionFeature.Member
                      )) && (
                      <div className="col-12 pt-2">
                        <i
                          onClick={() => {
                            $("#editProfileInline").removeClass("d-none");
                            $("#ProfileInline").addClass("d-none");
                          }}
                          className="fas fa-pencil-alt edit-pencil-icon float-right"
                        />
                      </div>
                    )}
                    <div className="col-md-5">
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoNickname,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoNickname
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Nickname: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.nickname == null ||
                            profileform.nickname == ""
                              ? "none"
                              : profileform.nickname}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoSpouseName,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoSpouseName
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Spouse Name: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.spousename == null ||
                            profileform.spousename == ""
                              ? "none"
                              : profileform.spousename}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoPreviousLastName,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoPreviousLastName
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Previous Last Name: </strong>
                          </span>
                          <span className="card-text">
                            {" "}
                            {profileform.previouslastname == null ||
                            profileform.previouslastname == ""
                              ? "none"
                              : profileform.previouslastname}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoShirtSize,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoShirtSize
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Shirt Size: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.shirtsize == null ||
                            profileform.shirtsize == ""
                              ? "none"
                              : profileform.shirtsize}
                          </span>
                        </div>
                      )}
                      {/* {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoVetStatus,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoVetStatus
                        )) && (
                          <div className="py-1">
                            <span className="card-subtitle pr-1 mb-2 text-muted">
                              <strong>Veteran: </strong>
                            </span>
                            <span className="card-text">
                              {detail.vetstatus &&
                                detail.vetstatus != null &&
                                detail.vetstatus != "" &&
                                (detail.vetstatus.trim().toUpperCase() == "B" ||
                                  detail.vetstatus.trim() == "P" ||
                                  detail.vetstatus.trim() == "V")
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                        )} */}
                    </div>
                    <div className="col-md-7">
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoGender,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoGender
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Gender: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.gender == null ||
                            profileform.gender == ""
                              ? "none"
                              : this.getGenderLabel(profileform.gender)}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.PersonalInfoDateOfBirth,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.PersonalInfoDateOfBirth
                        )) && (
                        <div className="row py-1">
                          <div class="col-8">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>Birthday: </strong>
                            </span>
                            <span className="card-text">
                              {member.dateofbirth == null ||
                              member.dateofbirth == ""
                                ? "none"
                                : member.dateofbirth}
                            </span>
                          </div>
                          <div class="col-4">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>Age: </strong>
                            </span>
                            <span className="card-text">
                              {member.dateofbirth == null ||
                              member.dateofbirth == ""
                                ? "none"
                                : calculate_age(member.dateofbirth)}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="py-1 row">
                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialPacDonation,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialPacDonation
                          )) && (
                          <div className="col-4 pr-lg-2">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>PAC: </strong>
                            </span>

                            <span
                              onMouseEnter={() => this.toggleMask("PAC", true)}
                              onMouseLeave={() => this.toggleMask("PAC", false)}
                              onClick={() =>
                                this.toggleMask("PAC", !this.state.PACShow)
                              }
                              className="card-text position-relative"
                            >
                              {this.state.PACShow
                                ? member.pacdonation != null &&
                                  member.pacdonation != ""
                                  ? formate_amount(member.pacdonation)
                                  : "none"
                                : "$*****"}
                              {/*for avoid hover bouncing we have added this span*/}
                              <span className="position-absolute left-0 opacity-0">
                                {(member.pacdonation != null &&
                                member.pacdonation != ""
                                  ? formate_amount(member.pacdonation)
                                  : "none"
                                ).length > 6
                                  ? formate_amount(member.pacdonation)
                                  : "$*****"}
                              </span>
                            </span>
                          </div>
                        )}
                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialDrfDonation,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialDrfDonation
                          )) && (
                          <div className="col-4 pr-lg-2">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>DRF: </strong>
                            </span>

                            <span
                              onMouseEnter={() => this.toggleMask("DRF", true)}
                              onMouseLeave={() => this.toggleMask("DRF", false)}
                              onClick={() =>
                                this.toggleMask("DRF", !this.state.DRFShow)
                              }
                              className="card-text position-relative"
                            >
                              {this.state.DRFShow
                                ? member.drfdonation != null &&
                                  member.drfdonation != ""
                                  ? formate_amount(member.drfdonation)
                                  : "none"
                                : "$*****"}
                              {/*for avoid hover bouncing we have added this span*/}
                              <span className="position-absolute left-0 opacity-0">
                                {(member.drfdonation != null &&
                                member.drfdonation != ""
                                  ? formate_amount(member.drfdonation)
                                  : "none"
                                ).length > 6
                                  ? formate_amount(member.drfdonation)
                                  : "$*****"}
                              </span>
                            </span>
                          </div>
                        )}
                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialNcfDonation,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialNcfDonation
                          )) && (
                          <div className="col-4 pr-lg-2">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>NCF: </strong>
                            </span>

                            <span
                              onMouseEnter={() => this.toggleMask("NCF", true)}
                              onMouseLeave={() => this.toggleMask("NCF", false)}
                              onClick={() =>
                                this.toggleMask("NCF", !this.state.NCFShow)
                              }
                              className="card-text position-relative"
                            >
                              {this.state.NCFShow
                                ? member.ncf != null && member.ncf != ""
                                  ? formate_amount(member.ncf)
                                  : "none"
                                : "$*****"}
                              {/*for avoid hover bouncing we have added this span*/}
                              <span className="position-absolute left-0 opacity-0">
                                {(member.ncf != null && member.ncf != ""
                                  ? formate_amount(member.ncf)
                                  : "none"
                                ).length > 6
                                  ? formate_amount(member.ncf)
                                  : "$*****"}
                              </span>
                            </span>
                          </div>
                        )}
                      </div>
                      {(AuthService.canOwnView(
                        PermissionFeature.DatesPlannedRetirementDate,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.DatesPlannedRetirementDate
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Planned Retirement: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.plannedretirementdate == null ||
                            profileform.plannedretirementdate == ""
                              ? "none"
                              : profileform.plannedretirementdate}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.DatesMandatoryRetirementDate,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.DatesMandatoryRetirementDate
                        )) &&
                        // member.natcaBargainingUnitObject &&
                        // (member.natcaBargainingUnitObject.code == "0053" ||
                        //   member.natcaBargainingUnitObject.code == "0061") &&
                        detail.unit &&
                        (detail.unit == "0053" || detail.unit == "0061") && (
                          <div className="py-1">
                            <span className="card-subtitle mb-2 text-muted">
                              <strong>Mandatory Retirement: </strong>
                            </span>
                            <span className="card-text">
                              {profileform.mandatoryretirementdate == null ||
                              profileform.mandatoryretirementdate == ""
                                ? "none"
                                : profileform.mandatoryretirementdate}
                            </span>
                          </div>
                        )}

                      {(AuthService.canOwnView(
                        PermissionFeature.DatesRetirementDate,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.DatesRetirementDate
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Retirement Date: </strong>
                          </span>
                          <span className="card-text">
                            {profileform.retirementdate == null ||
                            profileform.retirementdate == ""
                              ? "none"
                              : profileform.retirementdate}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {(AuthService.canOwnUpdate(
                    PermissionFeature.Member,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberUpdate(PermissionFeature.Member)) && (
                    <div className="row d-none" id="editProfileInline">
                      <div className="col-12 pt-2">
                        <i
                          onClick={() => {
                            $("#editProfileInline").addClass("d-none");
                            $("#ProfileInline").removeClass("d-none");
                            handleResetProfile();
                          }}
                          className="fas fa-times c-pointer float-right"
                        />
                      </div>
                      <form className="form-horizontal w-100">
                        <div className="row mx-0">
                          <div className="col-lg-5">
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoNickname,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoNickname
                              )) && (
                              <div className="py-1">
                                <div>
                                  <span className="card-subtitle mb-2 pr-1 text-secondary">
                                    Nickname:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.PersonalInfoNickname,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.PersonalInfoNickname
                                  ) ? (
                                    <div className="pl-0">
                                      <MDBInput
                                        containerClass="mt-0 mb-2"
                                        className="py-0 mb-0"
                                        name="nickname"
                                        value={
                                          profileform.nickname != null
                                            ? profileform.nickname
                                            : ""
                                        }
                                        onChange={changeHandlerPf_text}
                                        hint="provide your nickname"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoNickname,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoNickname
                                          )
                                        }
                                        group
                                        type="text"
                                      />
                                      <span className="d-block text-danger">
                                        {profileform.nickname_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {member.nickname == null ||
                                      member.nickname == ""
                                        ? "none"
                                        : profileform.nickname}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoSpouseName,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoSpouseName
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                                  Spouse Name:
                                </span>
                                {AuthService.canOwnUpdate(
                                  PermissionFeature.PersonalInfoSpouseName,
                                  this.props.memberid
                                ) ||
                                AuthService.canMemberUpdate(
                                  PermissionFeature.PersonalInfoSpouseName
                                ) ? (
                                  <div className="">
                                    <MDBInput
                                      containerClass="mt-0 mb-2"
                                      className="py-0 mb-0"
                                      name="spousename"
                                      hint="Provide your Spouse Name"
                                      value={
                                        profileform.spousename != null
                                          ? profileform.spousename
                                          : ""
                                      }
                                      onChange={changeHandlerPf_text}
                                      disabled={
                                        !AuthService.canOwnUpdate(
                                          PermissionFeature.PersonalInfoSpouseName,
                                          this.props.memberid
                                        ) &&
                                        !AuthService.canMemberUpdate(
                                          PermissionFeature.PersonalInfoSpouseName
                                        )
                                      }
                                      group
                                      type="text"
                                    />
                                    <span className="d-block text-danger">
                                      {profileform.spousename_err}
                                    </span>
                                  </div>
                                ) : (
                                  <span className="card-text">
                                    {member.spousename == null ||
                                    member.spousename == ""
                                      ? "none"
                                      : member.spousename}
                                  </span>
                                )}
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoPreviousLastName,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoPreviousLastName
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle pr-1 mb-2 text-secondary">
                                  Previous Last Name:
                                </span>
                                <span className="card-text">
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.PersonalInfoPreviousLastName,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.PersonalInfoPreviousLastName
                                  ) ? (
                                    <div className="">
                                      <MDBInput
                                        containerClass="mt-0 mb-2"
                                        className="py-0 mb-0"
                                        name="previouslastname"
                                        hint="Provide your Previous Last Name"
                                        value={
                                          profileform.previouslastname != null
                                            ? profileform.previouslastname
                                            : ""
                                        }
                                        onChange={changeHandlerPf_text}
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoPreviousLastName,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoPreviousLastName
                                          )
                                        }
                                        group
                                        type="text"
                                      />
                                      <span className="d-block text-danger">
                                        {profileform.previouslastname_err}
                                      </span>
                                    </div>
                                  ) : member.previouslastname == null ||
                                    member.previouslastname == "" ? (
                                    "none"
                                  ) : (
                                    member.previouslastname
                                  )}
                                </span>
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoShirtSize,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoShirtSize
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                                  Shirt Size:
                                </span>
                                {AuthService.canOwnUpdate(
                                  PermissionFeature.PersonalInfoShirtSize,
                                  this.props.memberid
                                ) ||
                                AuthService.canMemberUpdate(
                                  PermissionFeature.PersonalInfoShirtSize
                                ) ? (
                                  <div className="">
                                    <div class="md-form form-group mt-0 mb-2">
                                      <select
                                        //className="browser-default custom-select"
                                        onChange={changeHandlerShirtSizes}
                                        name="shirtsize"
                                        className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoShirtSize,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoShirtSize
                                          )
                                        }
                                      >
                                        {shirtsizes.map((item, index) => {
                                          return (
                                            <option
                                              value={item.value}
                                              selected={
                                                profileform.shirtsize ==
                                                item.value
                                              }
                                            >
                                              {item.text}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                ) : (
                                  <span className="card-text">
                                    {member.shirtsize == null ||
                                    member.shirtsize == ""
                                      ? "none"
                                      : member.shirtsize}
                                  </span>
                                )}
                              </div>
                            )}
                            {AuthService.canOwnView(
                              PermissionFeature.PersonalInfoVetStatus,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberView(
                              PermissionFeature.PersonalInfoVetStatus
                            ) ? (
                              <div className="py-1">
                                <span className="card-subtitle mb-2 text-muted">
                                  <strong>Vet Status: </strong>
                                </span>
                                <span className="card-text">
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.PersonalInfoVetStatus,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.PersonalInfoVetStatus
                                  ) ? (
                                    <>
                                      <MDBInput
                                        containerClass="mt-0 mb-2"
                                        className="py-0 mb-0"
                                        name="vetstatus"
                                        hint="Vet Status"
                                        value={profileform.vetstatus}
                                        onChange={
                                          this.props.changeHandlerVetStatus
                                        }
                                        group
                                        type="text"
                                      />
                                      <span className="d-block text-danger">
                                        {profileform.vetstatus_err}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="card-text">
                                      {detail.vetstatus &&
                                      detail.vetstatus != null &&
                                      detail.vetstatus != "" &&
                                      (detail.vetstatus.trim().toUpperCase() ==
                                        "B" ||
                                        detail.vetstatus.trim() == "P" ||
                                        detail.vetstatus.trim() == "V")
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="col-lg-7">
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoGender,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoGender
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle pr-1 mb-2 text-secondary">
                                  Sex:
                                </span>
                                {AuthService.canOwnUpdate(
                                  PermissionFeature.PersonalInfoGender,
                                  this.props.memberid
                                ) ||
                                AuthService.canMemberUpdate(
                                  PermissionFeature.PersonalInfoGender
                                ) ? (
                                  <div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input
                                        value="Male"
                                        onChange={changeHandlerGender}
                                        type="radio"
                                        id="Sex1"
                                        name="gender"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoGender,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoGender
                                          )
                                        }
                                        checked={
                                          profileform.gender == "Male" ||
                                          profileform.gender == "M"
                                        }
                                        class="custom-control-input"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="Sex1"
                                      >
                                        Male
                                      </label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input
                                        value="Female"
                                        onChange={changeHandlerGender}
                                        type="radio"
                                        id="Sex2"
                                        name="gender"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoGender,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoGender
                                          )
                                        }
                                        checked={
                                          profileform.gender == "Female" ||
                                          profileform.gender == "F"
                                        }
                                        class="custom-control-input"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="Sex2"
                                      >
                                        Female
                                      </label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input
                                        value="X"
                                        onChange={changeHandlerGender}
                                        type="radio"
                                        id="Sex3"
                                        name="gender"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoGender,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoGender
                                          )
                                        }
                                        checked={profileform.gender == "X"}
                                        class="custom-control-input"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="Sex3"
                                      >
                                        Nonbinary and/or Intersex
                                      </label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input
                                        value="N"
                                        onChange={changeHandlerGender}
                                        type="radio"
                                        id="Sex4"
                                        name="gender"
                                        disabled={
                                          !AuthService.canOwnUpdate(
                                            PermissionFeature.PersonalInfoGender,
                                            this.props.memberid
                                          ) &&
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.PersonalInfoGender
                                          )
                                        }
                                        checked={profileform.gender == "N"}
                                        class="custom-control-input"
                                      />
                                      <label
                                        class="custom-control-label"
                                        for="Sex4"
                                      >
                                        Prefer not to answer
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  <span className="card-text">
                                    {member.gender == null ||
                                    member.gender == ""
                                      ? "none"
                                      : member.gender}
                                  </span>
                                )}
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.PersonalInfoDateOfBirth,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.PersonalInfoDateOfBirth
                              )) && (
                              <div className="row py-1">
                                <div class="col-8">
                                  <span className="card-subtitle mb-2 pr-1 text-secondary">
                                    Birthday:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.PersonalInfoDateOfBirth,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.PersonalInfoDateOfBirth
                                  ) ? (
                                    <div className="md-form form-group mt-0 mb-2">
                                      <InputMask
                                        value={
                                          profileform.dateofbirth != null
                                            ? profileform.dateofbirth
                                            : ""
                                        }
                                        onChange={changeHandlerPf_date}
                                        name="dateofbirth"
                                        data-type="P"
                                        mask="99/99/9999"
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                      />
                                      <span className="text-danger">
                                        {profileform.dateofbirth_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {member.dateofbirth == null ||
                                      member.dateofbirth == ""
                                        ? "none"
                                        : member.dateofbirth}
                                    </span>
                                  )}
                                </div>
                                <div class="col-4">
                                  <span className="card-subtitle mb-2 pr-1 text-muted">
                                    Age:
                                  </span>
                                  <span className="card-text">
                                    {member.dateofbirth == null ||
                                    member.dateofbirth == ""
                                      ? "none"
                                      : calculate_age(member.dateofbirth)}
                                  </span>
                                </div>
                              </div>
                            )}

                            <div className="py-1 row">
                              {(AuthService.canOwnView(
                                PermissionFeature.FinancialPacDonation,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.FinancialPacDonation
                                )) && (
                                <div className="col-4">
                                  <span className="card-subtitle pr-1 mb-2 text-secondary">
                                    PAC:
                                  </span>

                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.FinancialPacDonation,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.FinancialPacDonation
                                  ) ? (
                                    <div className="">
                                      <InputMask
                                        className="py-0 mb-0"
                                        containerClass="mt-0 mb-2"
                                        name="pacdonation"
                                        icon="dollar-sign"
                                        value={
                                          profileform.pacdonation != null
                                            ? profileform.pacdonation
                                            : ""
                                        }
                                        onChange={changeHandlerPf_amount}
                                        disabled={
                                          !(
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.FinancialPacDonation,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.FinancialPacDonation
                                            )
                                          )
                                        }
                                        type="text"
                                        placeholder="$$"
                                        group
                                      />
                                      <span className="d-block text-danger">
                                        {profileform.pacdonation_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {member.pacdonation != null &&
                                      member.pacdonation != ""
                                        ? formate_amount(member.pacdonation)
                                        : "none"}
                                    </span>
                                  )}
                                </div>
                              )}
                              {(AuthService.canOwnView(
                                PermissionFeature.FinancialDrfDonation,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.FinancialDrfDonation
                                )) && (
                                <div className="col-4">
                                  <span className="card-subtitle pr-1 mb-2 text-secondary">
                                    DRF:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.FinancialDrfDonation,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.FinancialDrfDonation
                                  ) ? (
                                    <div className="">
                                      <MDBInput
                                        containerClass="mt-0 mb-2"
                                        className="py-0 mb-0"
                                        name="drfdonation"
                                        hint="$$"
                                        icon="dollar-sign"
                                        value={
                                          profileform.drfdonation != null
                                            ? profileform.drfdonation
                                            : ""
                                        }
                                        onChange={changeHandlerPf_amount}
                                        disabled={
                                          !(
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.FinancialDrfDonation,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.FinancialDrfDonation
                                            )
                                          )
                                        }
                                        group
                                        type="text"
                                      />

                                      <span className="d-block text-danger">
                                        {profileform.drfdonation_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {member.drfdonation != null &&
                                      member.drfdonation != ""
                                        ? formate_amount(member.drfdonation)
                                        : "none"}
                                    </span>
                                  )}
                                </div>
                              )}
                              {(AuthService.canOwnView(
                                PermissionFeature.FinancialNcfDonation,
                                this.props.memberid
                              ) ||
                                AuthService.canMemberView(
                                  PermissionFeature.FinancialNcfDonation
                                )) && (
                                <div className="col-4">
                                  <span className="card-subtitle pr-1 mb-2 text-secondary">
                                    NCF:
                                  </span>
                                  {AuthService.canOwnUpdate(
                                    PermissionFeature.FinancialNcfDonation,
                                    this.props.memberid
                                  ) ||
                                  AuthService.canMemberUpdate(
                                    PermissionFeature.FinancialNcfDonation
                                  ) ? (
                                    <div className="">
                                      <MDBInput
                                        containerClass="mt-0 mb-2"
                                        className="py-0 mb-0"
                                        name="ncf"
                                        hint="$$"
                                        icon="dollar-sign"
                                        value={
                                          profileform.ncf != null
                                            ? profileform.ncf
                                            : ""
                                        }
                                        onChange={changeHandlerPf_amount}
                                        disabled={
                                          !(
                                            AuthService.canOwnUpdate(
                                              PermissionFeature.FinancialNcfDonation,
                                              this.props.memberid
                                            ) ||
                                            AuthService.canMemberUpdate(
                                              PermissionFeature.FinancialNcfDonation
                                            )
                                          )
                                        }
                                        group
                                        type="text"
                                      />
                                      <span className="d-block text-danger">
                                        {profileform.ncf_err}
                                      </span>
                                    </div>
                                  ) : (
                                    <span className="card-text">
                                      {member.ncf != null && member.ncf != ""
                                        ? formate_amount(member.ncf)
                                        : "none"}
                                    </span>
                                  )}
                                </div>
                              )}
                            </div>

                            {(AuthService.canOwnUpdate(
                              PermissionFeature.DatesPlannedRetirementDate,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberUpdate(
                                PermissionFeature.DatesPlannedRetirementDate
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle pr-1 mb-2 text-secondary">
                                  Planned Retirement:
                                </span>
                                <div className="md-form form-group mt-0 mb-2">
                                  <InputMask
                                    value={
                                      profileform.plannedretirementdate != null
                                        ? profileform.plannedretirementdate
                                        : ""
                                    }
                                    onChange={changeHandlerPf_date}
                                    name="plannedretirementdate"
                                    data-type="P"
                                    mask="99/99/9999"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                  />
                                  <span className="text-danger">
                                    {profileform.plannedretirementdate_err}
                                  </span>
                                </div>
                              </div>
                            )}

                            {(AuthService.canOwnUpdate(
                              PermissionFeature.DatesRetirementDate,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberUpdate(
                                PermissionFeature.DatesRetirementDate
                              )) && (
                              <div className="py-1">
                                <span className="card-subtitle pr-1 mb-2 text-secondary">
                                  Retirement Date:
                                </span>
                                <div className="md-form form-group mt-0 mb-2">
                                  <InputMask
                                    value={
                                      profileform.retirementdate != null
                                        ? profileform.retirementdate
                                        : ""
                                    }
                                    onChange={changeHandlerPf_date}
                                    name="retirementdate"
                                    data-type="P"
                                    mask="99/99/9999"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                  />
                                  <span className="text-danger">
                                    {profileform.retirementdate_err}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="col-12 py-3 text-left">
                            <button
                              onClick={handleFormSubmitProfile}
                              className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                              //type="submit"
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-sm btn-danger w-md waves-effect waves-light"
                              type="button"
                              id="memberDetailCancelBtn"
                              onClick={() => {
                                $("#editProfileInline").addClass("d-none");
                                $("#ProfileInline").removeClass("d-none");
                                handleResetProfile();
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>

          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactAddresses,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(
              PermissionFeature.ContactAddresses
            )) && (
            <AddressPopup
              addresses={this.state.profile.addresses}
              changeHandlerAddress={this.changeHandlerAddress}
              handleChangePAddress={this.props.handleChangePAddress}
              handleRemoveRow={this.props.handleRemoveRow}
              handleFormSubmitAddresses={this.props.handleFormSubmitAddresses}
              handleResetProfileAddress={this.props.handleResetProfileAddress}
              handleAddRow={this.props.handleAddRow}
              validateUSPS={this.props.validateUSPS}
              handleSelectChange={this.props.handleSelectChange}
              addressTypes={this.props.addressTypes}
              memberid={this.props.memberid}
              States={this.props.States}
              changeHandlerState={this.changeHandlerState}
            />
          )}

          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactAddresses,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(
              PermissionFeature.ContactAddresses
            )) && (
            <AddressModal
              showModal={this.state.showModal}
              onClose={this.closeModal}
              addressTypes={this.state.profile.addressTypes}
              changeHandlerAddress={this.changeHandlerAddress}
              handleChangePAddress={this.props.handleChangePAddress}
              handleRemoveRow={this.props.handleRemoveRow}
              handleFormSubmitAddresses={this.props.handleFormSubmitAddresses}
              handleResetProfileAddress={this.props.handleResetProfileAddress}
              handleAddRow={this.props.handleAddRow}
              handleSelectChange={this.props.handleSelectChange}
              addressTypes={this.props.addressTypes}
              memberid={memberid}
              refreshData={this.props.LoadData}
            />
          )}
          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactEmailAddresses,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(
              PermissionFeature.ContactEmailAddresses
            )) && (
            <EmailPopup
              emailAddresses={this.state.profile.emailAddresses}
              handleChangeEmail={this.props.handleChangeEmail}
              handleChangePEmail={this.props.handleChangePEmail}
              handleRemoveEmail={this.props.handleRemoveEmail}
              handleFormSubmitEmail={this.props.handleFormSubmitEmail}
              handleResetProfileEmailAddress={
                this.props.handleResetProfileEmailAddress
              }
              handleAddEmail={this.props.handleAddEmail}
              memberid={this.props.memberid}
            />
          )}
          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactEmailAddresses,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(
              PermissionFeature.ContactEmailAddresses
            )) && (
            <EmailModal
              showModal={this.state.showEmailModal}
              onClose={this.closeEmailModal}
              memberid={memberid}
              refreshData={this.props.LoadData}
              currentEmail={this.state.currentEmail}
            />
          )}
          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactPhones,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(PermissionFeature.ContactPhones)) && (
            <PhonePopup
              phones={this.state.profile.phones}
              handleChangePhone={this.props.handleChangePhone}
              handleChangePPhone={this.props.handleChangePEmail}
              handleRemovePhone={this.props.handleRemovePhone}
              handleFormSubmitPhone={this.props.handleFormSubmitPhone}
              handleResetProfilePhones={this.props.handleResetProfilePhones}
              handleAddPhone={this.props.handleAddPhone}
              handleSelectChangePhoneType={
                this.props.handleSelectChangePhoneType
              }
              phoneTypes={this.props.phoneTypes}
              refreshData={this.props.LoadData}
              memberid={memberid}
            />
          )}
          {(AuthService.canOwnUpdate(
            PermissionFeature.ContactPhones,
            this.props.memberid
          ) ||
            AuthService.canMemberUpdate(PermissionFeature.ContactPhones)) && (
            <PhonesModal
              showModal={this.state.showPhoneModal}
              onClose={this.closePhoneModal}
              phoneTypes={this.props.phoneTypes}
              memberid={memberid}
              refreshData={this.props.LoadData}
            />
          )}

          {
            <VerificationModal
              showModal={this.state.showVerficationModal}
              onClose={this.closeVerificationModal}
              memberid={memberid}
              Legislative={member.allowtext_legislative}
              NewsAlert={member.allowtext_newsalerts}
              memberNumber={this.props.member.membernumber}
              refreshData={this.props.LoadData}
              resetTextMessageSubscription={
                this.props.resetTextMessageSubscription
              }
            />
          }
          {
            <FacebookIDModal
              showModal={this.state.showFacebookIdModal}
              resetFacebookSubscription={this.props.resetFacebookSubscription}
              closeFacebookIdFormModal={this.closeFacebookIdModal}
              memberId={memberid}
              LoadData={this.props.LoadData}
              FacebookId={this.state.FacebookId}
              FacebookEdit={this.state.FacebookEdit}
            />
          }
          {
            <ConfirmationModal
              confirm={this.confirm}
              modal={this.state.isConfirmModalOpen}
              toggle={this.toggleConfirm}
              Phone={this.state.phoneNumber}
              Type={this.state.delType}
              Id={this.state.delId}
            />
          }
        </div>
      </AUX>
    );
  }
}

export default UserProfile;
