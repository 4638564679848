import React, { Component } from "react";
import SavedSearchCustomizer from "../SavedSearchCustomizer";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import AuthService from "../../../Auth/AuthService";
import { connect } from "react-redux";
import mailGunEmailActions from "../../../../store/mailgunEmails/action";
import savedSearchAction from "../../../../store/saveSearch/action";
import actionFilter from "../../../../store/advancedfilter/action";
import EmailListService from "../../../Email/EmailListService";
import MailGunEmailTypes from "../../../Email/Enums/MailGunEmailTypes";
import MG_DeleteEmailList from "../../../Email/ModalPopups/MG_DeleteEmailList";
import SortableComponent from "../../../AdvanceSearch/SavedSearches/SortableComponent";
import arrayMove from "array-move";
import AssignMember from "../../../AdvanceSearch/SavedSearches/AssignMemberModal";
import ConfirmationModal from "../../../AdvanceSearch/SavedSearches/ConfirmationModal";

// import { MDBInput } from "mdbreact";
import { Card, CardBody, Collapse } from "reactstrap";
import FiltersService from "./FiltersService";
import FilterEmailConfigTabs from "../../../Email/Enums/FilterEmailConfigTabs";
import * as SavedSearchTypes from "../../../AdvanceSearch/SavedSearches/SavedSearchTypes";
import * as Permissions from "../../../Permissions/PermissionValues";
import { withRouter } from "react-router-dom";

export class SettingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteEmailListModal: false,
      selectedFields: [],
      memberId: AuthService.getProfile().memberData.id,
      showAssignMemberModal: false,
      UserFirstName: "",
      UserLirstName: "",
      index: -1,
      prevent: false,
      showColumnsOrder: false,
      showhideCols: "Show",
      resultData: [],
      searchNameFieldVal: this.props.savedSearchState.editFilterRow.search_name,
    };
    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let deleteResponse = null;
        const { emailListId, emailEntityType } = this.props.melstate;
        //Flow for Saved Searches
        debugger;
        if (emailEntityType == MailGunEmailTypes.Member) {
          // Added second Parameter to handle deletion of both List and Filter for SS.
          let shouldDeleteEmailList = false;
          const loggedInMember = this.state.memberId;
          const {
            editFilterRow: {
              search_name,
              id,
              search_type_id,
              real_search_type_id,
              owner_id, // incase of AllFilters screen
              member_id, //incase of MyFilter screen
              saved_search_id, //incase of shared filter
            },
          } = this.props.savedSearchState;
          let memberId = 0;
          let filterId = 0;
          let type = 0;
          if (real_search_type_id) {
            filterId = saved_search_id;
            memberId = member_id;
            type = real_search_type_id; //most probably Bucket or Ownership
            // 1 for Ownership, 2 for Bucket and 3 for Shared
          } else {
            filterId = id;
            type = search_type_id;
            if (owner_id && owner_id !== loggedInMember) {
              // Check if logged in member is not owner of filter
              memberId = owner_id;
            } else {
              memberId = loggedInMember; // If logged in member is owner of filter
            }
          }
          if (emailListId > 0 && filterId > 0) {
            ///Email List and Filter Both Exists
            await FiltersService.deleteFilterAPI(filterId, type, memberId)
              .then(({ data: response }) => {
                $(".loading").hide();
                if (response != null && response.status === "success") {
                  toast.success("Email list and Filter removed successfully!");
                  deleteResponse = 1;
                  $(".loading").hide();
                } else {
                  console.error("failed");
                  deleteResponse = 0;
                  $(".loading").hide();
                }
              })
              .catch((error) => {
                deleteResponse = 0;
                $(".loading").hide();
                console.log(error);
              });
          }
          //else if (emailListId > 0) {
          //  //Deletes the MyNatca List from the System for Saved Searches.
          //  await EmailListService.DeleteSSEmailList(emailListId, true).then(
          //    ({ data: response }) => {
          //      $(".loading").hide();
          //      if (response.status === "success") {
          //        toast.success("Email list removed successfully!");
          //        deleteResponse = 1;
          //        $(".loading").hide();
          //      } else {
          //        toast.error(response.message);
          //        deleteResponse = 0;
          //        $(".loading").hide();
          //      }
          //    }
          //  );
          //}
          //Just the Filter no Mailing list
          else if (filterId > 0 && emailListId == 0) {
            await FiltersService.deleteFilterAPI(filterId, type, memberId)
              .then(({ data: response }) => {
                $(".loading").hide();
                if (response != null && response.status === "success") {
                  toast.success("Filter removed successfully!");
                  deleteResponse = 1;
                  $(".loading").hide();
                } else {
                  console.error("failed");
                  deleteResponse = 0;
                  $(".loading").hide();
                }
              })
              .catch((error) => {
                deleteResponse = 0;
                $(".loading").hide();
                console.log(error);
              });
          }
        }
        //Flow for  Facility Region Mailing lists
        else if (emailEntityType !== MailGunEmailTypes.Member) {
          await EmailListService.DeleteFRTEmailList(emailListId).then(
            ({ data: response }) => {
              $(".loading").hide();
              if (response.status === "success") {
                toast.success("Email list removed successfully!");
                deleteResponse = 1;
                $(".loading").hide();
              } else {
                toast.error(response.message);
                deleteResponse = 0;
                $(".loading").hide();
              }
            }
          );
        }

        this.toggleDeleteEmailListModal();

        this.handleClose();
        if (deleteResponse == 1) {
          //this.props.onDeleteEmailList();
          //this.onModalExited();
          const {
            location: { pathname },
          } = this.props.history;
          if (pathname == "/directory/myfilters") {
            //My Filters
            const {
              editFilterRow,
              loggedInUserFilters,
            } = this.props.savedSearchState; //current filter opened
            this.props.resetEmailConfigDetails();
            this.props.resetEditFilterModalState();
            this.props.toggleEditFilterModal({}, false);
            //Remove the filter from the Redux List
            const currentFilters = loggedInUserFilters.filter(
              (f) => f.id !== editFilterRow.id
            );
            //Will Reload New Filters Listing Screen and DropDown of Saved Searches
            this.props.setMemberFilters(currentFilters);
          }
          // All Filters
          else {
            const {
              editFilterRow,
              allSystemFilters,
            } = this.props.savedSearchState; //current filter opened
            this.props.resetEmailConfigDetails();
            this.props.resetEditFilterModalState();
            this.props.toggleEditFilterModal({}, false);
            //Remove the filter from the Redux List
            const currentFilters = allSystemFilters.filter(
              (f) => f.id !== editFilterRow.id
            );
            //Will Reload New Filters Listing Screen and DropDown of Saved Searches
            this.props.setAllSystemFilters(currentFilters);
          }
        }
      };

      return this;
    };
    this.membersMethods = () => {
      let array = [];
      this.setMember = (args) => {
        array.push(args);
        if (this.state._dataArray != null) {
          this.setState({
            _dataArray: this.state._dataArray.concat(array),
          });
        } else {
          this.setState({ _dataArray: array });
        }
      };
      return this;
    };
    this.confirm = () => {
      this.confirmed = async () => {
        debugger;
        if (this.state.index !== -1) {
          let delMemberID = this.state._dataArray.splice(this.state.index, 1);
          let values = this.state._dataArray.filter(
            (e, i) => i !== this.state.index
          );
          this.setState({ _dataArray: values });
          let filterId = 0;
          const {
            editFilterRow: {
              id = 0,
              real_search_type_id = 0,
              saved_search_id = 0,
            },
          } = this.props.savedSearchState;
          if (real_search_type_id) {
            filterId = saved_search_id;
          } else {
            filterId = id;
          }
          //const url = `/api/SavedSearch/DeleteSharedSearch/`;
          const body = {
            saved_search_id: filterId,
            member_id: delMemberID[0].id, //Member to deleted
            is_pinned: this.state.is_pinned,
          };
          // axios.post(url, body).then(({ data }) => {
          //   if (data.status.toLowerCase() === "success") {
          //     //toast.success(data.message);
          //   } else {
          //     //toast.error(data.message);
          //   }
          // });
          var response = await EmailListService.DeleteSharedSearch(body);
          if (response.status.toLowerCase() === "success") {
            toast.success(response.message);
          } else {
            toast.error(response.message);
          }
        }
        this.toggleConfirm();
      };
      return this;
    };
  }
  forceSearchRefresh = async (forceRefresh) => {
    const { editFilterRow } = this.props.savedSearchState;
    if (forceRefresh === true) {
      let {
        editFilterRow: {
          id = 0,
          search_type_id = 0,
          saved_search_id = 0,
          owner_id = 0,
          real_search_type_id = 0,
        },
      } = this.props.savedSearchState;
      let filterId = 0;
      let owner = 0;
      if (search_type_id == SavedSearchTypes.SHARED_ITEM) {
        filterId = id; //Prmary key of SharedSearch Table
        owner = AuthService.getProfile().memberData.id; //The person whom it was Shared
      } else {
        filterId = id; ///SavedSearch Primary key
        owner = owner_id;
      }
      let shared_type_id = 0;
      let shared_search_id = 0;
      if (
        search_type_id == SavedSearchTypes.SHARED_ITEM &&
        real_search_type_id == SavedSearchTypes.BUCKET_ITEM
      ) {
        shared_type_id = real_search_type_id;
        shared_search_id = saved_search_id;
      }
      const body = {
        SearchId: filterId, //Switch at API will handle
        MemberId: owner,
        SearchType: search_type_id,
      };

      this.showSpinner();
      let response = await FiltersService.GetSavedSearchFilter(body);
      if (response && response.status) {
        if (response.status.toLowerCase() === "success") {
          this.props.setSavedSearchCriteria({
            savedSearchCriteriaDetail: response,
            searchTypeId: search_type_id,
            searchId: filterId,
            sharedSaveSearchTypeId: shared_type_id,
            sharedBucketSearchId: shared_search_id,
          });
          this.getDefaultColumns(
            filterId, // API would handle the Primary Key both for ownership and shared
            search_type_id,
            owner ///Owner Id set for Shared Search???
          );
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error("Could not retrieve saved search detail.");
      }
      this.hideSpinner();
      // axios
      //   .post("/api/SavedSearch/GetSavedSearchFilter", body)
      //   .then(({ data: response }) => {
      //     this.hideSpinner();
      //     if (response.status) {
      //       if (response.status.toLowerCase() === "success") {
      //         this.props.setSavedSearchCriteria({
      //           savedSearchCriteriaDetail: response,
      //           searchTypeId: editFilterRow.search_type_id,
      //           searchId: editFilterRow.id,
      //         });
      //         this.getDefaultColumns(
      //           editFilterRow.id,
      //           editFilterRow.search_type_id,
      //           editFilterRow.owner_id
      //         );
      //       } else {
      //         toast.error(response.message);
      //       }
      //     } else {
      //       toast.error("Could not retrieve saved search detail.");
      //     }
      //   });
    }
  };
  showSpinner = () => {
    $(".loading").show();
  };
  hideSpinner = () => {
    $(".loading").hide();
  };
  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteEmailListModal: !this.state.showDeleteEmailListModal,
    });
  };
  selectedFieldSorted = (oldIndex, newIndex) => {
    let sortedFields = arrayMove(
      [...this.state.selectedFields],
      oldIndex,
      newIndex
    );
    let items = arrayMove([...this.state.items], oldIndex, newIndex);
    this.setState({
      selectedFields: sortedFields,
      items: items,
    });
  };
  getDefaultColumns = async (searchId, searchType, owner_id) => {
    if (
      isNaN(searchId) ||
      searchId == null ||
      isNaN(searchType) ||
      searchType == null
    ) {
      return;
    }

    this.setState({
      searchId: searchId,
      searchTypeId: searchType,
      //showModal: true
    });
    let data = await EmailListService.GetSearchColumns(
      searchId, //It is shared search then it will be the primary key of SharedSearch table
      // else if it a SavedSearch then it will be the  primary key of SavedSearches table

      owner_id,
      searchType
    );
    if (data) {
      var tempArr = [];
      this.setState({
        resultData: data.searchColumns,
        _dataArray: data.membersList,
      });
      this.setState({
        items: tempArr,
        initialItems: tempArr,
        selectedFields: [...data.searchColumns],
      });
    }
    // const url = `/api/SavedSearch/GetColumns/`;

    // const body = {
    //   SearchId: searchId,
    //   MemberId: this.state.memberId,
    //   SearchType: searchType,
    // };
    // axios.post(url, body).then(({ data }) => {
    //   var tempArr = [];
    //   this.setState({
    //     resultData: data.searchColumns,
    //     _dataArray: data.membersList,
    //   });

    //   //tempArr.push(data.searchColumns);
    //   // for (let i = 0; i < data.searchColumns.length; i++)
    //   //   tempArr.push(data.searchColumns[i]);
    //   this.setState({
    //     items: tempArr,
    //     initialItems: tempArr,
    //     selectedFields: [...data.searchColumns],
    //   });
    // });
  };
  componentDidUpdate(prevProps) {
    const { currentTab } = this.props;
    const { resultData } = this.state;
    if (
      currentTab !== prevProps.currentTab &&
      currentTab == FilterEmailConfigTabs.Settings &&
      _.isEmpty(resultData)
    ) {
      let {
        editFilterRow: {
          search_name = "",
          id = 0,
          search_type_id = 0,
          owner_id = 0,
          real_search_type_id,
        },
      } = this.props.savedSearchState;
      if (real_search_type_id) {
        //Shared Searches have real_search_type_id property when getting data from
        // [HttpGet("getMemberSearches/{memberId}")] API
        owner_id = this.state.memberId; //Logged in user Id
        //"id" in this case is the primary key of SharedSearch Table
      }
      // Else in case of Saved Searches/Ownership the owner_id will be member id by which it was created
      this.getDefaultColumns(id, search_type_id, owner_id);
      //GetColumns API handles it with the Type Id value, that why no care for Id here
    }
    if (
      this.props.triggerMapFieldsWithSearchMethod !==
      prevProps.triggerMapFieldsWithSearchMethod
    ) {
      this.mapFieldsWithSearch();
    }
  }
  componentDidMount = () => {};

  toggleConfirm = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };

  assignMemberListClosed = () => {
    this.setState({
      showAssignMemberModal: false,
    });
  };
  setIndex = (user, index) => {
    this.setState({
      index: index,
      UserFirstName: user.firstName,
      UserLastName: user.lastName,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });

    console.log(this.state.prevent);
  };
  // mapFieldsWithSearchV1() {
  //   this.showSpinner();
  //   const body = this.state.selectedFields;
  //   let { selectedFields } = this.state;
  //   selectedFields = JSON.parse(JSON.stringify(body));
  //   const {
  //     editFilterRow: { search_name = "" },
  //   } = this.props.savedSearchState;
  //   // this.state.SearchName
  //   axios
  //     .post(
  //       `/api/SavedSearch/MapcolumnsWithSearch?savedSearchId=${this.state.searchId}&memberId=${this.state.memberId}&searchName=${search_name}`,
  //       body
  //     )
  //     .then(({ data: response }) => {
  //       if (response.status === "success") {
  //         // toast.success("Field(s) successfully mapped with search.");
  //         // this.setState({
  //         //   requiresResultRefresh: true,
  //         //   SearchName: "",
  //         // });
  //         if (this.state._dataArray != null) {
  //           const body = this.state._dataArray;
  //           axios
  //             .post(`/api/SavedSearch/SaveSharedSearch?searchId=${this.state.searchId}`, body)
  //             .then(({ data: response }) => {
  //               if (response.status === "success") {
  //                 let message = "Search details saved successfully.";
  //                 if (this.state.searchTypeId) {
  //                   if (this.state.searchTypeId == 1) {
  //                     message = "Saved search saved successfully.";
  //                   } else if (this.state.searchTypeId == 2) {
  //                     message = "Bucket search saved successfully.";
  //                   }
  //                 }
  //                 toast.success(message);
  //                 // this.setState({
  //                 //   requiresResultRefresh: true,
  //                 // });
  //                 this.handleClose();
  //               } else {
  //                 toast.error(response.message);
  //               }
  //               this.hideSpinner();
  //             });
  //         }
  //         this.handleClose();
  //         this.setState({ prevent: true });

  //         this.props.setSavedSearchCriteria(null);
  //         this.props.updateAndSelect(this.state.searchId, true);

  //         //   this.forceSearchRefresh(this.state.requiresResultRefresh);
  //       } else {
  //         toast.error(response.message);
  //       }
  //       this.hideSpinner();
  //     });
  // }
  // Called from EditFilterModal.js File's Save Changes
  mapFieldsWithSearch = async () => {
    this.showSpinner();
    ///const body = this.state.selectedFields;
    let { selectedFields } = this.state;
    //selectedFields = JSON.parse(JSON.stringify(body));
    let {
      editFilterRow: {
        search_name = "",
        id = 0,
        search_type_id = 0,
        saved_search_id = 0,
        owner_id = 0,
        real_search_type_id,
      },
    } = this.props.savedSearchState;

    const { searchNameFieldVal } = this.state;
    const { pathname } = window.location;
    let filterName = "";
    let isNameEdited = false;
    if (searchNameFieldVal != search_name) {
      filterName = searchNameFieldVal;
      isNameEdited = true;
    } else {
      filterName = search_name;
    }
    let filterId = 0;
    if (search_type_id == SavedSearchTypes.SHARED_ITEM) {
      filterId = saved_search_id; //Shared record will have `saved_search_id` column for saved-search
    } else {
      filterId = id; //
    }
    let APIParams = {
      searchId: filterId,
      memberId: this.state.memberId, // This member id will be used to fetch Member info
      search_name: filterName,
      selectedColumns: selectedFields,
      membersList: this.state._dataArray,
    };

    let response = await EmailListService.MapColumnsWithSearch(APIParams);
    if (response && response.status === "success") {
      //toast.success("Column Field(s) successfully mapped with search.");
      // this.setState({
      //   requiresResultRefresh: true,
      //   SearchName: "",
      // });
      if (response.status === "success") {
        let message = "Search details saved successfully.";
        if (this.state.searchTypeId) {
          if (this.state.searchTypeId == 1) {
            message = "Saved search saved successfully.";
          } else if (this.state.searchTypeId == 2) {
            message = "Bucket search saved successfully.";
          }
        }
        toast.success(message);
        // this.setState({
        //   requiresResultRefresh: true,
        // });
        this.handleClose();
      } else {
        toast.error(response.message);
      }
      if (APIParams.membersList != null) {
        let response = await EmailListService.SaveSharedSearchMembers(
          APIParams
        );
      }
      if (isNameEdited) {
        if (pathname == "/directory/myfilters") {
          this.props.reloadMemberFilters();
        } else {
          let data = await FiltersService.getAllSystemFilters();
          this.props.setAllSystemFilters(data);
        }
        await this.props.changeFilterName(searchNameFieldVal);
      }
      this.handleClose();
      this.setState({ prevent: true });

      this.props.setSavedSearchCriteria(null);
      //this.props.updateAndSelect(this.state.searchId, true);
      //   self.forceSearchRefresh(self.state.requiresResultRefresh);
    }

    this.hideSpinner();
  };
  modifySearchName = async () => {
    let filterId = 0;
    const {
      editFilterRow: { id = -1, saved_search_id = -1, search_type_id = -1 },
    } = this.props.savedSearchState;
    if (search_type_id == SavedSearchTypes.SHARED_ITEM) {
      filterId = saved_search_id;
    } else {
      filterId = id;
    }
    const { searchNameFieldVal } = this.state;
    let model = {
      SearchName: searchNameFieldVal,
      SearchId: filterId,
    };
    if (_.isEmpty(model.SearchName)) {
      toast.error("Name is Mandatory!");
    } else {
      let response = await FiltersService.updateSaveSearchName(model);
      if (response && response.status === "success") {
        toast.success(response.message);
        await this.props.changeFilterName(searchNameFieldVal);
      }
    }
  };
  changeFilterName = async (e) => {
    const { value } = e.target;
    this.setState({ searchNameFieldVal: value });
  };
  render() {
    const {
      mailingListName,
      emailListId,
      entityKey,
      isEmailListExists,
    } = this.props.melstate;
    const {
      editFilterRow: {
        search_name = "",
        id = 0,
        search_type_id = 0,
        saved_search_id = 0,
      },
    } = this.props.savedSearchState;
    let filterId = 0;
    if (search_type_id == SavedSearchTypes.SHARED_ITEM) {
      filterId = saved_search_id; //Shared record will have `saved_search_id` column for saved-search
    } else {
      filterId = id; //
    }
    var columnsConfigProps = {
      items: [...this.state.selectedFields],
      dataKeyField: "id",
      textField: "column_name",
      showDeleteIcon: true,
      onItemDelete: this.removeSelectedField,
      showModal: this.props.showModal,
    };
    let deleteBtnLabel = "";
    if (emailListId !== 0) {
      deleteBtnLabel = "Delete the Saved Filter and Email List";
    } else {
      deleteBtnLabel = "Delete the Saved Filter"; // emailListId ==  0 => Means No Email List Exists
    }
    let deleteBtnText = "";
    let { searchNameFieldVal } = this.state;
    if (emailListId !== 0) {
      //Email list exists
      deleteBtnText = _.isEmpty(mailingListName)
        ? `${search_name}'s List`
        : mailingListName;
    } else {
      deleteBtnText = search_name;
    }
    //console.log("hererererer" + deleteBtnText);
    return (
      <>
        <div className="settingsTab p-1">
          <div className="settingActions">
            {EmailListService.canDeleteEmailList() && (
              <div className="row">
                <span className="font-weight-450">{deleteBtnLabel}</span>
              </div>
            )}

            {EmailListService.canDeleteEmailList() && (
              <div className="row">
                <button
                  type="button"
                  onClick={this.toggleDeleteEmailListModal}
                  className={`btn btn-danger mt-2 mb-1 float-right`}
                >
                  Delete <span className="small ">"{deleteBtnText}"</span>
                </button>
              </div>
            )}

            <div className="row">
              {/* <div className="col-sm-6">
                <div className="form-group mt-2">
                  <label for="filterName weight-450">
                    Rename the Saved Filter
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterName"
                    aria-describedby="Help"
                    placeholder="Saved Filter Name"
                    value={search_name}
                    disabled={id <= 0 ? false : true}
                  />
                  <small id="Help" className="form-text text-muted">
                  Name related to Filter
                </small>
                </div>
              </div> */}
              {/* <MDBInput
              name="SearchName"
              id="Name"
              onChange={this.handleChange}
              label={"Rename the Saved Filter"}
              group
              type="text"
              required
              width="50%"
              value={search_name}
              disabled={id <= 0 ? false : true}
            >
              <div className="invalid-feedback">
                Saved Search Name is required.
              </div>
            </MDBInput> */}
              <div className="col-sm-3 pl-0 pb-1">
                <label for="filterName ">
                  <span className="font-weight-450">
                    Rename the Saved Filter
                  </span>
                </label>
                <div class="input-group">
                  <input
                    type="text"
                    className="form-control h-auto"
                    id="filterName"
                    aria-describedby="Help"
                    placeholder="Saved Filter Name"
                    value={searchNameFieldVal}
                    onChange={(e) => this.changeFilterName(e)}
                    //disabled={id <= 0 ? false : true}
                    disabled={!EmailListService.canUpdate()}
                  />
                  <div class="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={this.modifySearchName}
                    >
                      <i class="fa fa-save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {AuthService.canView(Permissions.Customize_Fields) && (
              <>
                <div className="row">
                  <span className="font-weight-450">
                    {/* Configure Columns for the Saved Filter  */}
                    Select Fields to Display
                  </span>
                </div>

                <h4 className="card-subtitle mb-2 text-muted">
                  <SavedSearchCustomizer
                    savedSearchId={filterId}
                    searchTitle={""}
                    searchType={search_type_id}
                    forceSearchRefresh={this.forceSearchRefresh.bind(this)}
                    pencilClass="pencil"
                    showConfigColsBtn={true} //Only for Setting Tab from Edit Filter Modal
                  ></SavedSearchCustomizer>
                </h4>
              </>
            )}
          </div>
          <div className="nestedComps">
            {AuthService.canView(Permissions.Customize_Fields) && (
              <Card>
                <h6
                  className="card-header cursorPointer pl-1"
                  onClick={() =>
                    this.setState(
                      {
                        showColumnsOrder: !this.state.showColumnsOrder,
                      },
                      () => {
                        if (this.state.showColumnsOrder) {
                          this.setState({ showhideCols: "Hide" });
                        } else {
                          this.setState({
                            showhideCols: "Show",
                          });
                        }
                      }
                    )
                  }
                >
                  <i
                    className={
                      this.state.showColumnsOrder
                        ? "float-right fas arrow-icon fa-chevron-up"
                        : "float-right fas arrow-icon fa-chevron-down"
                    }
                  ></i>
                  {this.state.showhideCols} Columns Order
                  {/* ///////////Used for Reordering the columns////////// */}
                </h6>
                <Collapse
                  className="colsCardCollapse"
                  isOpen={this.state.showColumnsOrder}
                >
                  <CardBody className="pl-1 pr-2">
                    <div className="sortable-items">
                      {
                        <SortableComponent
                          {...columnsConfigProps}
                          onSortEnd={(oldIndex, newIndex) =>
                            this.selectedFieldSorted(oldIndex, newIndex)
                          }
                        ></SortableComponent>
                      }
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            )}

            {AuthService.canView(Permissions.Share_Search) && (
              <>
                <h4 className="card-subtitle mb-2 mt-2">
                  {" "}
                  Assign Managers {"    "}
                  <span
                    onClick={() => {
                      this.setState({ showAssignMemberModal: true });
                    }}
                    className="fa fa-plus-circle text-success"
                  ></span>
                </h4>
                <small>
                  Below is a list of all users who can access this saved searc.
                  Sharing this search with another user allows them to view the
                  BUEs resulting from the search, they will not be able to view
                  member details unless their permissions allow. The results
                  will be displayed with the filter criteria and results grid
                  you see when viewing the search.
                </small>
                <div className="mt-2">
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Facility</td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state._dataArray ? (
                        this.state._dataArray.map((user, index) => (
                          <tr key={user.id}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td style={{ width: "15%" }}>
                              {user.facility}
                              <i
                                className="fas fa-trash-alt float-right"
                                style={{ "margin-left": "60%" }}
                                onClick={() => {
                                  this.toggleConfirm();
                                  this.setIndex(user, index);
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <p>
                          <div class="container h-100">
                            <div class="row FoFManagersTable">
                              <h5>No Data Found!</h5>
                            </div>
                          </div>
                        </p>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>

        <br />
        <MG_DeleteEmailList
          modal={this.state.showDeleteEmailListModal}
          confirm={this.confirmEmailList}
          emailListExists={isEmailListExists}
          isFilterModal={true}
          toggle={this.toggleDeleteEmailListModal}
        />
        <ConfirmationModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirm}
          UserFirstName={this.state.UserFirstName}
          UserLastName={this.state.UserLastName}
        />
        <AssignMember
          showModal={this.state.showAssignMemberModal}
          onClose={this.assignMemberListClosed}
          membersMethods={this.membersMethods}
        ></AssignMember>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      melstate: state.mailGunEmail,
      filterState: state.advancedFilter,
      savedSearchState: state.saveSearch,
    }),
    {
      ...mailGunEmailActions,
      ...actionFilter,
      ...savedSearchAction,
    },
    null,
    { forwardRef: true }
  )(SettingsTab)
);
