import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: "light",
    },

    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf", // Bold font
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  GrienvantSecondFooter: {
    marginTop: 10,
    textDecoration: "underline",
    fontSize: 13,
  },
  grievanceHeaderLeft: {
    width: "50%",
  },
  container: {
    flexDirection: "row", // Align children horizontally
  },
  mB1: {
    marginBottom: 10,
  },
  w50: {
    width: "50%",
  },
  w35: {
    width: "35%",
  },
  pL4: {
    paddingLeft: "34px",
  },
  boldText: {
    fontFamily: "Open Sans",

    fontWeight: "bold",
  },

  pT4: {
    paddingTop: "34px",
  },
  pT3: {
    paddingTop: "21px",
  },
  pT2: {
    paddingTop: "13px",
  },
  grievanceHeaderRight: {
    width: "50%",
    paddingRight: 15,
    paddingTop: 15,
    textAlign: "right",
  },
  headerImageView: {
    marginLeft: 100,
  },
  GrienvantFooter: {
    marginTop: 30,
  },
  image: {
    width: 80,
    height: 80,
  },
  floatLeft: {
    float: "left",
  },
  sec: {
    //marginTop: 80
  },
  Dear: {
    marginTop: 30,
    marginBottom: 30,
  },
  afterSalution: {
    marginTop: 10,
  },
  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    fontFamily: "Open Sans",
    fontSize: 12,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    marginTop: 20,
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  textCenter: { textAlign: "center" },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export function PARPdfDocument(props) {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.container}>
          <View style={styles.grievanceHeaderLeft}>
            <Image
              style={styles.image}
              src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
            />
          </View>
          <View style={styles.grievanceHeaderRight}>
            <Text>National Air Traffic Controllers Association</Text>
            <Text>AFL-CIO</Text>
            <Text>
              {props.data.regionDescription} ({props.data.regionName})
            </Text>

            <Text style={styles.pT4}>{props.data.pdfGenerateDate}</Text>
          </View>
        </View>

        <View style={{ ...styles.pT4 }}>
          <Text style={{ ...styles.boldText, ...styles.GrienvantSecondFooter }}>
            SENT VIA EMAIL AND CERTIFIED MAIL:
          </Text>
          <Text style={{ ...styles.pT4 }}>{props.data.recipientName}</Text>
          <Text style={{ ...styles.w35 }}>
            {props.data.memberFacilityAddress}
          </Text>
        </View>

        <View style={{ ...styles.pT4 }}>
          <Text style={{ ...styles.boldText, ...styles.GrienvantSecondFooter }}>
            Re: PAR Elevation Grievances
          </Text>
          <Text style={{ ...styles.pT3 }}>{props.data.recipientName}</Text>
          <Text style={{ ...styles.pT3 }}>
            This letter is to request that, in accordance with the applicable
            Collective Bargaining Agreement, the following grievance(s) be
            submitted for Pre-Arbitration Review:
          </Text>
          {/*<Text style={{ ...styles.pL4, ...styles.pT3 }}>1. 24-C90-1 (GREV1234556678789)</Text>*/}
          {/*<Text style={{ ...styles.pL4}}>2. 24-C90-2 (GREV1234566787701)</Text>*/}
          {/*<Text style={{ ...styles.pL4 }}>3. 24-ORD-4 (GREV1234556678790)</Text>*/}
        </View>
        <Text style={styles.pT2}>Regards,</Text>
        <Text style={styles.pT2}> {props.data.rvpName} </Text>
        <Text> Regional Vice President </Text>
        <Text> NATCA {props.data.regionDescription} </Text>
        {props.data.arvps &&
          props.data.arvps.map((arvp) => (
            <>
              <Text style={styles.pT3}>
                CC: {arvp.membername}, {props.data.regionName} Alternate
                Regional Vice President
              </Text>
            </>
          ))}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
