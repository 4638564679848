import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import Services from "../GrievanceService";
import { DatePickerInput } from "rc-datepicker";
import { useHistory } from "react-router-dom";
import GrievanceArticleVoilatedModal from "./GrievanceArticleVoilatedModal";
import moment from "moment";
import DatePicker from "react-datepicker";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { debounce } from "throttle-debounce";

class GrievanceEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stepId: null,
      grievantId: null,
      grievant_err: "",
      isConfirmModalOpen: false,
      contractArticleDetailsData: "",
      contractArticleDetailsHeader: "",
      localContractsSelection: [],
      masterGrievanceData: {
        grievant: {},
        representative: "",
        recipient: "",
        contract: "",
        isAdverse: false,
        isMeeting: true,
        dateOfVoilation: "",
        dateSubmitted: "",
        subject: "",
        statementOfFacts: "",
        remedySought: "",
      },
      representativesList: [],
      representative: {},
      representative_err: "",
      recipientsList: [],
      recipient: {},
      recipient_err: "",
      articlesList: [],
      article: {},
      article_err: "",
      grievanceId: "",
      isAdverse: false,
      isMeeting: true,
      dateOfVoilation: "",
      dateOfVoilation_err: "",
      dateSubmitted: "",
      dateSubmitted_err: "",
      subject: "",
      subject_err: "",
      statementOfFacts: "",
      statementOfFacts_err: "",
      remedySought: "",
      remedySought_err: "",
      originalGrievanceData: {},

      grievantsSearchObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
    };

    this.loadDataByGrievantId = this.loadDataByGrievantId.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.getRecipientByRepresentativeId = this.getRecipientByRepresentativeId.bind(
      this
    );
    this.grievantSearchHandler = this.grievantSearchHandler.bind(this);
    this.removeLocalArticleItem = this.removeLocalArticleItem.bind(this);
    this.openInformationModal = this.openInformationModal.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };
  openInformationModal = (articleId) => {
    $(".loading").show();
    Services.GetArticleDetails(articleId).then((result) => {
      var articleArray = result.data.articleDetails;
      var html = "";
      for (let index = 0; index < articleArray.length; index++) {
        const element = articleArray[index];
        html =
          html +
          " <div><b>" +
          element.sectionName +
          "</b> <div>" +
          element.sectionContent +
          "</div> </div> <br />";
      }
      //html.replace('<ol type="a">', '<ol type="a" style="margin-left:50px;">');
      this.setState({
        isConfirmModalOpen: !this.state.isConfirmModalOpen,
        contractArticleDetailsData: html,
        contractArticleDetailsHeader: result.data.articleName,
      });
      $(".loading").hide();
    });
  };
  removeLocalArticleItem = (id) => {
    const updatedSelection = this.state.localContractsSelection.filter(
      (item) => item.id !== id
    );
    this.setState({ localContractsSelection: updatedSelection });
  };

  autocompleteSearch = () => {
    this._fetch();
  };
  _fetch = async () => {
    var data = await this.loadGrievantsData();
  };
  //componentWillReceiveProps(prevProps) {
  //    this.setState({
  //        stepId: prevProps.StepId,
  //        grievanceId: prevProps.GrievanceId,
  //    });
  //}
  async componentDidUpdate(prevProps) {
    if (this.props.GrievanceId != this.state.grievanceId) {
      await this.setState({
        grievanceId: this.props.GrievanceId,
      });
      await this.loadGrievanceData();
    }
  }
  loadGrievanceData = async () => {
    $(".loading").show();
    var grievanceId = this.state.grievanceId;
    if (grievanceId) {
      var masterData = await this.loadGrievanceDetails(grievanceId);

      if (masterData.dataLoad) {
        if (
          masterData &&
          masterData.data &&
          !_.isEmpty(masterData.data.articles)
        ) {
          let contracts = masterData.data.articles.map((x) => {
            return { id: x.id, name: x.articleName };
          });
          await this.setState({
            localContractsSelection: contracts,
          });
        } else if (
          masterData &&
          masterData.master &&
          masterData.master.contract &&
          Object.keys(masterData.master.contract).length !== 0
        ) {
          await this.setState({
            localContractsSelection: [masterData.master.contract],
          });
        }
        if (masterData.master.steps.length > 0) {
          this.setState({
            originalGrievanceData: masterData,
            representativesList: masterData.representativesList,
            recipientsList: masterData.recipientsList,
            articlesList: masterData.articlesList,
            article: masterData.master.contract,
            grievant: masterData.master.grievant,
            recipient: masterData.master.recipient,
            representative: masterData.master.representative,
            masterGrievanceData: masterData.master,
            isAdverse: masterData.master.isAdverse,
            isMeeting: masterData.master.isMeeting,
            dateOfVoilation: masterData.master.dateOfVoilation,
            dateSubmitted: masterData.master.dateSubmitted,
            remedySought: masterData.master.remedySought,
            statementOfFacts: masterData.master.statementOfFacts,
            subject: masterData.master.subject,
          });
        }
      }
    }
    $(".loading").hide();
  };

  loadGrievanceDetails = async (grievanceId) => {
    $(".loading").show();
    let data = null;
    let masterObject = {};
    let representativesList = [];
    let recipientsList = [];
    let articlesList = [];
    let dataLoad = false;
    let masterData = await Services.LoadGrievanceDataById(grievanceId);
    if (!_.isEmpty(masterData.data)) {
      dataLoad = true;
      data = masterData.data;
      masterObject = {
        steps: masterData.data.steps,
        grievant: {
          id: masterData.data.grievant.id,
          name:
            masterData.data.grievant.firstname +
            " " +
            masterData.data.grievant.lastname,
        },
        representative: {
          id: masterData.data.representative.id,
          name:
            masterData.data.representative.firstname +
            " " +
            masterData.data.representative.lastname,
        },
        recipient: {
          id: masterData.data.recipient.id,
          name: masterData.data.recipient.recipientName,
        },
        contract: masterData.data.article
          ? {
              id: masterData.data.article.contractId,
              name: masterData.data.article.articleName,
            }
          : {},
        isAdverse: masterData.data.isAdverse,
        isMeeting: masterData.data.steps[0].isMeeting,
        dateOfVoilation: masterData.data.steps[0].dateOfVoilation,
        dateSubmitted: masterData.data.steps[0].dateSubmitted,
        subject: masterData.data.steps[0].subject,
        statementOfFacts: masterData.data.steps[0].statementOfFacts,
        remedySought: masterData.data.steps[0].remedySought,
      };
    }

    let result = await Services.LoadDataByGrievant(grievanceId);
    if (!_.isEmpty(result.data)) {
      _.forEach(result.data.representativesList, function(el, index, arr) {
        representativesList.push({
          id: el.id,
          name: el.firstname + " " + el.lastname,
        });
      });
      _.forEach(result.data.recipientsList, function(el, index, arr) {
        recipientsList.push({
          id: el.id,
          name: el.recipientName,
        });
      });

      _.forEach(result.data.articlesList, function(el, index, arr) {
        articlesList.push({
          id: el.id,
          name: el.articleName,
        });
      });
    }

    return {
      data: data,
      master: masterObject,
      representativesList: representativesList,
      recipientsList: recipientsList,
      articlesList: articlesList,
      dataLoad: dataLoad,
    };
  };

  componentDidMount() {
    this.loadGrievantsData();
  }

  handleClose = () => {
    this.props.OnClose();

    this.setState({
      representativesList: [],
      representative: this.state.originalGrievanceData.master.representative,
      representative_err: "",
      recipient: this.state.originalGrievanceData.master.recipient,
      recipient_err: "",
      article: this.state.originalGrievanceData.master.contract,
      article_err: "",
      grivant: this.state.originalGrievanceData.master.grievant,
      grivant_err: "",
      isAdverse: this.state.originalGrievanceData.master.isAdverse,
      isMeeting: this.state.originalGrievanceData.master.isMeeting,
      dateOfVoilation: this.state.originalGrievanceData.master.dateOfVoilation,
      dateOfVoilation_err: "",
      dateSubmitted: this.state.originalGrievanceData.master.dateSubmitted,
      dateSubmitted_err: "",
      subject: this.state.originalGrievanceData.master.subject,
      subject_err: "",
      statementOfFacts: this.state.originalGrievanceData.master
        .statementOfFacts,
      statementOfFacts_err: "",
      remedySought: this.state.originalGrievanceData.master.remedySought,
      remedySought_err: "",

      grievantsSearchObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {};

  grievantSearchHandler = (SearchTerms) => {
    let property = { ...this.state.grievantsSearchObj };
    property.SearchTerms = SearchTerms;
    this.setState({ grievantsSearchObj: property }, () => {
      this.autocompleteSearchDebounced();
    });
  };
  loadGrievantsData = async () => {
    let grievantsSearchObj = this.state.grievantsSearchObj;
    let data = await Services.LoadGrievants(
      grievantsSearchObj.SearchTerms,
      grievantsSearchObj.Offset,
      grievantsSearchObj.Fetch
    );

    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.grievantsData = data.result;
        await this.setState({
          grievantsData: data.result,
        });
      }
    }
  };
  loadDataByGrievantId = async (id) => {
    let result = await Services.LoadDataByGrievant(id);
    let representativesList = [];
    let articlesList = [];
    if (!_.isEmpty(result.data)) {
      _.forEach(result.data.representativesList, function(el, index, arr) {
        representativesList.push({
          id: el.id,
          name: el.firstname + " " + el.lastname,
        });
      });
      _.forEach(result.data.articlesList, function(el, index, arr) {
        articlesList.push({
          id: el.id,
          contractId: el.contractId,
          name: "Article " + el.articleIndex + " - " + el.articleName,
        });
      });

      this.state.representative = !_.isEmpty(representativesList)
        ? _.head(representativesList)
        : {};
      this.state.article = !_.isEmpty(articlesList) ? _.head(articlesList) : {};

      await this.setState({
        representativesList: representativesList,
        articlesList: articlesList,
        representative: this.state.representative,
        article: this.state.article,
      });

      this.getRecipientByRepresentativeId();
    }
  };
  async getRecipientByRepresentativeId() {
    if (this.state.representative != null && this.state.representative.id) {
      let recipientsList = [];
      let result = await Services.GetRecipient(this.state.representative.id);
      _.forEach(result, function(el, index, arr) {
        recipientsList.push({
          id: el.id,
          name: el.recipientName,
        });
      });
      this.state.recipientsList = recipientsList;
    } else {
      this.state.recipientsList = [];
    }
    let recipient = !_.isEmpty(this.state.recipientsList)
      ? _.head(this.state.recipientsList)
      : {};
    await this.setState({
      recipientsList: this.state.recipientsList,
      recipient: recipient,
    });
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    var contracts = this.state.localContractsSelection;

    const contractIds = contracts.map((obj) => obj.id);
    this.showSpinner();
    let grievanceObject = {
      grievanceId: this.state.grievanceId,
      grievantId: this.state.grievant.id,
      recipientName: this.state.recipient.name,
      articleId: this.state.article.id,
      isAdverseAction: this.state.isAdverse,
      status: 2,
      currentStepId: 1,
      StepData: {
        fAANumber: "",
        dateOfVoilation: this.state.dateOfVoilation,
        representativeId: this.state.representative.id,
        recipientId: this.state.recipient ? this.state.recipient.id : 0,
        dateSubmitted: this.state.dateSubmitted,
        subject: this.state.subject,
        contractId: this.state.article.id,
        contractIds: contractIds,
        statementOfFacts: this.state.statementOfFacts,
        remedySought: this.state.remedySought,
        isMeetingRequired: this.state.isMeeting,
      },
    };
    var formdata = new FormData();
    formdata.append("data", JSON.stringify(grievanceObject));
    var a = this.props;
    let result = await Services.EditGrievance(formdata);
    if (result) {
      window.location.reload();
      this.handleClose();
    }
    this.hideSpinner();
  };

  render() {
    return (
      <>
        <Modal
          className="EditGrievanceModal latest"
          show={this.props.ShowModal}
          onHide={this.handleClose}
          centered
          size="xl"
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                EDIT GRIEVANCE{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              id="EditGrievance"
              className="form-horizontal"
              onSubmit={this.handleFormSubmit}
              noValidate
            >
              <div className="row mx-0">
                <div className="col-md-12">
                  <div
                    className="col-md-6"
                    style={{ float: "left", marginTop: "15px" }}
                  >
                    <MDBInput
                      name=""
                      containerClass="my-0"
                      hint=" "
                      value={this.state.subject}
                      label="Subject of Grievance"
                      type="text"
                      outline
                      maxlength="200"
                      onChange={(event, value) => {
                        if (event.target.value) {
                          this.setState({
                            subject: event.target.value,
                          });
                        } else {
                          this.setState({
                            subject: event.target.value,
                            subject_err: "Please provide Subject",
                          });
                        }
                      }}
                    />
                    <span className="text-danger">
                      {this.state.subject_err}
                    </span>
                  </div>
                  <div className="col-md-6" style={{ float: "left" }} />
                </div>
                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <Autocomplete
                      options={
                        this.state.grievantsData ? this.state.grievantsData : []
                      }
                      filterOptions={(options, state) => options}
                      autoComplete={true}
                      autoHighlight={true}
                      onInputChange={(e, value, reason) => {
                        this.grievantSearchHandler(value);
                      }}
                      value={this.state.grievant}
                      onChange={(e, value, reason) => {
                        this.state.grievant = value;
                        let message = null;
                        if (value != null) {
                          message = null;
                          this.loadDataByGrievantId(value.id);
                        } else {
                          message = "Please provide Grievant!";
                        }
                        this.setState({
                          grievant: this.state.grievant,
                          grievant_err: message,
                        });
                      }}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            className:
                              "pb-1" +
                              (params.inputProps &&
                                " " + params.inputProps.className),
                          }}
                          variant="outlined"
                          label="Grievant"
                          InputLabelProps={{
                            shrink: true,
                            classes: { shrink: "mt-n1" },
                          }}
                          placeholder="Select Member..."
                          fullWidth
                        />
                      )}
                    />
                    <span className="text-danger">
                      {this.state.grievant_err}
                    </span>
                  </div>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <Autocomplete
                      options={
                        this.state.representativesList
                          ? this.state.representativesList
                          : []
                      }
                      filterOptions={(options, state) => options}
                      autoComplete={true}
                      autoHighlight={true}
                      value={this.state.representative}
                      onChange={(e, value, reason) => {
                        if (value != null) {
                          this.setState({
                            representative: value,
                          });
                        } else {
                          this.setState({
                            representative: this.state.representative,
                            representative_err:
                              "Please provide Representative!",
                          });
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            className:
                              "pb-1" +
                              (params.inputProps &&
                                " " + params.inputProps.className),
                          }}
                          variant="outlined"
                          label="Representative"
                          InputLabelProps={{
                            shrink: true,
                            classes: { shrink: "mt-n1" },
                          }}
                          placeholder="Select Rep..."
                          fullWidth
                        />
                      )}
                    />
                    <span className="text-danger">
                      {this.state.representative_err}
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "15px" }} className="col-md-12">
                  <div className="col-6" style={{ float: "left" }}>
                    <Autocomplete
                      options={
                        this.state.recipientsList
                          ? this.state.recipientsList
                          : []
                      }
                      filterOptions={(options, state) => options}
                      autoComplete={true}
                      autoHighlight={true}
                      value={this.state.recipient}
                      onChange={(e, value, reason) => {
                        if (value != null) {
                          this.setState({
                            recipient: value,
                            recipient_err: "",
                          });
                        } else {
                          this.setState({
                            recipient: this.state.recipient,
                            recipient_err: "Please provide Recipient!",
                          });
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            className:
                              "pb-1" +
                              (params.inputProps &&
                                " " + params.inputProps.className),
                          }}
                          variant="outlined"
                          label="Recipient"
                          InputLabelProps={{
                            shrink: true,
                            classes: { shrink: "mt-n1" },
                          }}
                          placeholder="Select Recipient ..."
                          fullWidth
                        />
                      )}
                    />
                    <span className="text-danger">
                      {this.state.recipient_err}
                    </span>
                  </div>
                  <div className="col-6" style={{ float: "left" }}>
                    <Autocomplete
                      options={
                        this.state.articlesList ? this.state.articlesList : []
                      }
                      filterOptions={(options, state) => options}
                      autoComplete={true}
                      autoHighlight={true}
                      value={this.state.article}
                      onChange={(e, value, reason) => {
                        let showInformation = true;
                        if (reason === "clear") {
                          showInformation = false;
                        }

                        if (value) {
                          const existingItem = this.state.localContractsSelection.find(
                            (item) => item.id === value.id
                          );

                          if (!existingItem) {
                            const updatedSelection = [
                              ...this.state.localContractsSelection,
                              value,
                            ];
                            this.setState({
                              localContractsSelection: updatedSelection,
                            });
                          }
                          this.state.article = {};
                          this.state.article_err = "";
                          if (showInformation)
                            this.setState({
                              showContractInfo: true,
                            });
                          else
                            this.setState({
                              showContractInfo: false,
                            });
                        }
                        //if (value != null) {
                        //  this.setState({
                        //    article: value,
                        //  });
                        //} else {
                        //  this.setState({
                        //    article: this.state.article,
                        //    article_err: "Please provide Article!",
                        //  });
                        //}
                      }}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            className:
                              "pb-1" +
                              (params.inputProps &&
                                " " + params.inputProps.className),
                          }}
                          variant="outlined"
                          label="Article"
                          InputLabelProps={{
                            shrink: true,
                            classes: { shrink: "mt-n1" },
                          }}
                          placeholder="Select Article Violated..."
                          fullWidth
                        />
                      )}
                    />
                    <span className="text-danger">
                      {this.state.article_err}
                    </span>
                    {this.state.localContractsSelection.length > 0 && (
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Article Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.localContractsSelection.map(
                            (item) =>
                              // Check if item and item.id are not null or undefined before rendering
                              item &&
                              item.id && (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td style={{ fontSize: "16px" }}>
                                    <span
                                      style={{
                                        cursor: "help",
                                        marginRight: "15px",
                                      }}
                                      onClick={() =>
                                        this.openInformationModal(item.id)
                                      }
                                    >
                                      <i class="fas fa-info-circle" />
                                    </span>
                                    <span
                                      onClick={() =>
                                        this.removeLocalArticleItem(item.id)
                                      }
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                <div style={{ marginTop: "15px" }} className="col-md-12">
                  <div className="col-md-6 mb-2" style={{ float: "left" }}>
                    <div className="py-1">
                      <span className="">Adverse Action?</span>
                      <div class="input-group mb-3">
                        <input
                          type="checkbox"
                          switch="bool"
                          checked={this.state.isAdverse}
                          onChange={() =>
                            this.setState({
                              isAdverse: !this.state.isAdverse,
                            })
                          }
                          id={"isAdverseAction"}
                          name={"isAdverseAction"}
                        />
                        <label
                          className="mb-n2"
                          htmlFor={"isAdverseAction"}
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2" style={{ float: "left" }}>
                    <div className="py-1">
                      <span className="">Meeting Requested?</span>
                      <div class="input-group mb-3">
                        <input
                          type="checkbox"
                          switch="bool"
                          checked={this.state.isMeeting}
                          onChange={() =>
                            this.setState({
                              isMeeting: !this.state.isMeeting,
                            })
                          }
                          id={"isMeetingRequested"}
                          name={"isMeetingRequested"}
                        />
                        <label
                          className="mb-n2"
                          htmlFor={"isMeetingRequested"}
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "15px" }} className="col-md-12">
                  <div className="col-md-6 mb-2" style={{ float: "left" }}>
                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                      <small>Date of Violation</small>
                    </span>
                    {/*<DatePickerInput
                      readonly="readonly"
                      onChange={(e, value, reason) => {
                        if (value != null) {
                          this.setState({
                            dateOfVoilation: value,
                          });
                        } else {
                            this.setState({
                            dateOfVoilation: this.state.dateOfVoilation,
                            dateOfVoilation_err: "Please provide DOV!",
                          });
                        }
                      }}
                      value={this.state.dateOfVoilation}
                                    />*/}
                    <DatePicker
                      dateForm="MM/DD/YYYY"
                      className="form-control bg-light rounded-0 "
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          this.setState({
                            dateOfVoilation: moment(date).format("MM/DD/YYYY"),
                          });
                        } else {
                          this.setState({
                            dateOfVoilation: date,
                            dateOfVoilation_err: "Please provide DOV!",
                          });
                        }
                      }}
                      selected={
                        this.state.dateOfVoilation
                          ? moment(this.state.dateOfVoilation).toDate()
                          : moment(new Date()).toDate()
                      }
                      placeholderText={"MM/DD/YYYY"}
                    />

                    <span className="text-danger">
                      {this.state.dateOfVoilation_err}
                    </span>
                  </div>

                  <div className="col-md-6 mb-2" style={{ float: "left" }}>
                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                      <small>Date Submitted</small>
                    </span>
                    {/*<DatePickerInput
                      readonly="readonly"
                      onChange={(e, value, reason) => {
                        if (value != null) {
                          this.setState({
                            dateSubmitted: value,
                          });
                        } else {
                          this.setState({
                            dateSubmitted: this.state.dateSubmitted,
                            dateSubmitted_err: "Please provide DOS!",
                          });
                        }
                      }}
                      value={this.state.dateSubmitted}
                                    />*/}
                    <DatePicker
                      dateForm="MM/DD/YYYY"
                      className="form-control bg-light rounded-0 "
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      onChange={(date) => {
                        if (moment(date).isValid()) {
                          this.setState({
                            dateSubmitted: moment(date).format("MM/DD/YYYY"),
                          });
                        } else {
                          this.setState({
                            dateSubmitted: date,
                            dateSubmitted_err: "Please provide DOS!",
                          });
                        }
                      }}
                      selected={
                        this.state.dateSubmitted
                          ? moment(this.state.dateSubmitted).toDate()
                          : moment(new Date()).toDate()
                      }
                      placeholderText={"MM/DD/YYYY"}
                    />

                    <span className="text-danger">
                      {this.state.dateSubmitted_err}
                    </span>
                  </div>
                </div>

                <div style={{ marginTop: "15px" }} className="col-md-12">
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label>Statement of Facts</label>
                    <textarea
                      name=""
                      containerClass="mb-0"
                      className="w-100 border"
                      value={this.state.statementOfFacts}
                      rows="5"
                      maxlength="500"
                      onChange={(evt, value, reason) => {
                        if (evt.target.value != null) {
                          this.setState({
                            statementOfFacts: evt.target.value,
                          });
                        } else {
                          this.setState({
                            statementOfFacts: this.state.statementOfFacts,
                            statementOfFacts_err: "Please provide SOF!",
                          });
                        }
                      }}
                    />
                    <span className="text-danger">
                      {this.state.statementOfFacts_err}
                    </span>
                  </div>
                  <div className="col-md-6" style={{ float: "left" }}>
                    <label>Remedy Sought</label>
                    <textarea
                      name=""
                      containerClass="mb-0"
                      className="w-100 border"
                      value={this.state.remedySought}
                      rows="5"
                      maxlength="500"
                      onChange={(evt, value, reason) => {
                        if (evt.target.value != null) {
                          this.setState({
                            remedySought: evt.target.value,
                          });
                        } else {
                          this.setState({
                            remedySought: this.state.remedySought,
                            remedySought_err: "Please provide SOF!",
                          });
                        }
                      }}
                    />
                    <span className="text-danger">
                      {this.state.remedySought_err}
                    </span>
                  </div>
                </div>
                <div
                  style={{ marginTop: "15px" }}
                  className="col-md-12 text-centered d-flex justify-content-center"
                >
                  <button
                    className="btn btn-sm btn-danger w-md cancelModal waves-effect waves-light"
                    type="button"
                    id="RecipientPopupCloseBtn"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={this.handleFormSubmit}
                    className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    EDIT
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <GrievanceArticleVoilatedModal
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          html={this.state.contractArticleDetailsData}
          header={this.state.contractArticleDetailsHeader}
        />
      </>
    );
  }
}

export default GrievanceEditModal;
